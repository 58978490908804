import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { ClickpostWrapperService } from '../../services/api/cp.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  sess_acc: any;
  api_details: any = {
    api_key: '',
    refresh_token: '',
    access_token: '',
    expiry_period: -1,
    tmp_timeout: -1,
    time_edit_mode: false,
    valid: true
  };

  subscriber_id: any = 'settings';

  constructor(private clickpostService: ClickpostWrapperService, private dataService: DataService,
    private ns: NotificationService, private utilityService: UtilityService, private router: Router) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.loadApiDetails();
    this.loadAccessToken();
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  regenerate(name: any) {
    let input: any;
    if (['api_key', 'refresh_token'].indexOf(name) > -1) {
      input = { name: name }
      this.clickpostService.regenerate(input).subscribe((response: any) => {
        this.api_details[name] = response[name];
        this.loadApiDetails();
        this.isAccessTokenValid();
      });
    } else {
      this.clickpostService.createAccessToken().subscribe((response: any) => {
        this.api_details.access_token = response.access_token;
        this.loadAccessToken();
        this.isAccessTokenValid();
      });
    }
  }

  isAccessTokenValid() {
    this.clickpostService.checkTokenValidity().subscribe((response: any) => {
      this.api_details.valid = response.valid_token;
    });
  }

  loadApiDetails() {
    this.clickpostService.getApiDetails().subscribe((response: any) => {
      this.api_details.api_key = response.api_key;
      this.api_details.refresh_token = response.refresh_token;
      this.api_details.expiry_period = response.expiry_period;
      this.api_details.tmp_timeout = response.expiry_period;
    });
  }

  loadAccessToken() {
    this.clickpostService.getToken().subscribe((response: any) => {
      this.api_details.access_token = response.access_token;
      this.isAccessTokenValid();
    });
  }

  showEditMode() {
    this.api_details.time_edit_mode = true;
    this.api_details.tmp_timeout = this.api_details.expiry_period;
  }

  saveTimeout() {
    let input:any = { time: this.api_details.expiry_period };
    this.clickpostService.updateTokenTimeout(input).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Timeout Updated Successfully.");
      this.api_details.time_edit_mode = false;
      this.loadApiDetails();
      this.isAccessTokenValid();
    });
  }

  cancelTimeout() {
    this.api_details.expiry_period = this.api_details.tmp_timeout;
    this.api_details.time_edit_mode = false;
  }

  ngOnDestroy(): void {
    this.dataService.offLoggedOut(this.subscriber_id);
  }
}
