import { AddUser } from '../../models/request/users';
import { AccountService } from '../../services/api/account.service';
import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  offset = 0;
  count = 20;
  totalItems = 0;
  users: any = [];
  deleteUserModalParams: any = {
    selectedUserToDelete: {
      _id: ''
    }
  };
  resetPasswordModalParams: any = {
    selectedUserToReset: {
      _id: ''
    }
  };
  saveUserModalParams: any = {
    add_user: new AddUser()
  };

  constructor(private accountService: AccountService, private ns: NotificationService,
    private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.getUsers();
  }

  openDeleteUserModal(data: any) {
      this.deleteUserModalParams = {
        selectedUserToDelete: data
      };
      this.jq.openModal('delUserModal');
  }

  deleteUser(params: any) {
    let input = {
      "id": params.selectedUserToDelete._id,
    }
    this.accountService.deleteAccounts(input).subscribe((response: any) => {
        this.jq.closeModal('delUserModal');
        this.ns.showAlert(CONSTANTS.SUCCESS, "User deleted successfully.");
        this.deleteUserModalParams = {};
        this.getUsers();
    });
  }

   resetPassword(data: any) {
      this.resetPasswordModalParams = {
        selectedUserToReset: data,
        resetPass: ''
      };
      this.jq.openModal('resetModal');
  }

  reset(params: any) {
    let input = {
      pwd: params.resetPass,
      user_id: params.selectedUserToReset._id
    };
    this.accountService.resetPassword(input).subscribe((response: any) => {
        this.jq.closeModal('resetModal');
        this.ns.showAlert(CONSTANTS.SUCCESS, "Password reset successfully.");
        this.resetPasswordModalParams = {};
        this.getUsers();
    });
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.getUsers();
  }

  getUsers() {
    let input = {
      "index": this.offset,
      "limit": 20,
      "type": "client",
      "offset": this.offset
    };
    this.accountService.getAccounts(input).subscribe((response: any) => {
      this.users = response.accounts;
      this.totalItems = response.count;
    });
  }

  addUser() {
    this.saveUserModalParams = {
      header: 'Add User',
      button_txt: 'ADD',
      add_user: new AddUser(),
      add: true
    };
    this.jq.openModal('saveUserModal');
  }

  updateUser(broker: any) {
    this.saveUserModalParams = {
      header: 'Update User',
      button_txt: 'UPDATE',
      add_user: JSON.parse(JSON.stringify(broker)),
      add: false
    };
    this.jq.openModal('saveUserModal');
  }

  saveUser(params: any) {
    let ip: any = {
      user: {
        full_name: params.add_user.full_name,
        email: params.add_user.email,
        phone: params.add_user.phone,
        password: params.add_user.password
      },
    };
    if (params.add) {
      ip.user_type = 'client';
    } else {
      ip.id = params.add_user._id;
    }
    let func: any = params.add ? this.accountService.addAccounts : this.accountService.updateAccounts;
    func(ip).subscribe((response: any) => {
      if (params.add) {
        this.ns.showAlert(CONSTANTS.SUCCESS, "User added successfully")
      } else {
        this.ns.showAlert(CONSTANTS.SUCCESS, "User updated successfully")
      }
      this.jq.closeModal('saveUserModal');
      this.getUsers();
    });
  }
}
