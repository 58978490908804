import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends ApiService {

  public getReports(data: any) {
    return this.getQueryParams(CONSTANTS.API.REPORT.GET_REPORT,data);
  }
  public downloadReport(data: any) {
    return this.download(CONSTANTS.API.REPORT.DOWNLOAD_REPORT, data);
  }
  public getRPReports(data: any) {
    return this.getQueryParams(CONSTANTS.API.REPORT.GET_RP_REPORT,data);
  }
  public downloadRPReport(data: any) {
    return this.download(CONSTANTS.API.REPORT.DOWNLOAD_RP_REPORT, data);
  }
}
