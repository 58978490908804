import { AccountService } from '../../../services/api/account.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../../constants';
import { NotificationService } from '../../../services/notification.service';
import { DataService } from '../../../services/data.service';
import { UtilityService } from '../../../services/utility.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  oldpass: any;
  newpass: any;
  confirmpass: any;
  subscriber_id: any = 'change-pwd';

  constructor(private accountService: AccountService, private dataService: DataService,
    private ns: NotificationService, private utilityService: UtilityService, private router: Router) { }

  ngOnInit(): void {
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  reset(){
    let input = {
      new_pwd: this.newpass,
      old_pwd: this.oldpass
    };
    this.accountService.changePassword(input).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Password reset successfully.");
      this.dataService.logOut({logged_out:true});
    });
  }
}
