import { Component } from '@angular/core';

import { DataService } from '../services/data.service';
import { MessagingService } from '../services/messaging.service';
import { CONSTANTS } from '../constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'SAAP';

  constructor(private dataService: DataService, private messagingService: MessagingService) {}

  ngOnInit() {
    this.messagingService.getFireMessaging().messages.subscribe((message: any) => {
      console.log('App component');
      console.log(message);
      this.dataService.fcmNotified(message);
    });
    let is_logged = (sessionStorage.getItem(CONSTANTS.COOKIE.ISLOGGEDIN) == 'true');
    if (is_logged) {
      this.messagingService.requestPermission();
    }
  }
}
