import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Errors {

    errorCode: any = { };

    getErrorMessage(key: any) {
        var errorCodes = this.errorCode;
        var swap = Object.keys(errorCodes).reduce((obj: any, key: any) => {
            obj[errorCodes[key]] = key;
            return obj;
        }, {});
        return swap[key];
    }
}
