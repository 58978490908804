import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class FCMService extends ApiService {

  public saveToken(data: any) {
    return this.put(CONSTANTS.API.FCM.SAVE_TOKEN, data);
  }
}
