import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { Errors } from './error.codes';
import { UtilityService } from '../services/utility.service';
import { CONSTANTS } from '../constants';
import { Router } from "@angular/router";
import { DataService } from "./data.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  header?: string | null;
  constructor(private notificationUtil: NotificationService,
    private utilityService: UtilityService,
    private router: Router,
    private dataService: DataService,
    private errors: Errors
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.header = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    if (this.header != undefined) {

      //Login without logout - Token present in cookie
      if (request.url.endsWith('auth/login')) {
      } else {
        //After login
        request = request.clone({
          headers: new HttpHeaders({
            'Authorization': "Bearer " + this.header,
          })
        });
      }
    }


    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
          this.dataService.logOut({logged_out:true});
        } else if ((error?.url ?? "").indexOf('auth/logout') > -1) {
          this.dataService.logOut({logged_out:true});
        } else {
          if (error.error.detail) {
            let message = error.error.detail[0].data || error.error.detail[0].msg || 'Internal error occurred. Please try after sometime.';
            this.notificationUtil.showAlert("error", message);
            this.dataService.apiErrorTrigerred({error});
          }
        }
        return throwError(error);
      }));

  }
}
