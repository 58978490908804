<nav aria-label="Page navigation example">
    <ul class="pagination">
        <li class="page-item" [ngClass]="{'disabled': pageNumber == 1 }">
            <a class="page-link" href="javascript:void(0)" aria-label="First" (click)="firstPageSelected()">
                <span aria-hidden="true">&laquo;&laquo;</span>
            </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': pageNumber == 1 }">
            <a class="page-link" href="javascript:void(0)" aria-label="Previous" (click)="previousPageSelected()">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>
        <li class="page-item" [ngClass]="{'active': page.selected, 'disabled': page.disabled }" *ngFor="let page of pages">
            <a class="page-link" href="javascript:void(0)" (click)="pageSelected(page)">{{ page.num }}</a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': pageNumber == totalPages }">
            <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="nextPageSelected()">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
        <li class="page-item" [ngClass]="{'disabled': pageNumber == totalPages }">
            <a class="page-link" href="javascript:void(0)" aria-label="Last" (click)="lastPageSelected()">
                <span aria-hidden="true">&raquo;&raquo;</span>
            </a>
        </li>
    </ul>
</nav>