<div class="container">
  <div class="row header-buffer">
    <div class="col-md-12">
      <h4>CUSTOMER</h4>
    </div>
  </div>
  <div class="row sub-header-buffer">
    <div class="col-md-3">
      <label>&nbsp;</label>
      <div class="form-group">
        <button class="btn btn-ecom btn-block" (click)="addUser()">
          <i class="fas fa fa-user-o"></i>&nbsp;&nbsp; ADD
        </button>
      </div>
    </div>
  </div>
  <div class="row sub-header-buffer">
    <table class="table ecom-table">
      <thead class="table-header">
        <tr>
          <th>
            <div>NAME</div>
          </th>
          <th>
            <div>PHONE</div>
          </th>
          <th>
            <div>EMAIL</div>
          </th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let s of users">
          <td>
            <div>{{s.full_name}}</div>
          </td>
          <td>
            <div>{{s.phone}}</div>
          </td>
          <td>
            <div>{{s.email}}</div>
          </td>
          <td>
            <i class="fas fa-key" (click)="resetPassword(s)">
            </i> &nbsp;
            <i class="fas fa-trash-alt" (click)="openDeleteUserModal(s)">
            </i> &nbsp;
            <i class="fas fa-edit" (click)="updateUser(s)">
            </i>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="15" class="center-align">
            <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
              <div class="col-md-12 pagenation">
                <app-pagination [totalItems]="totalItems"
                  (onPrevSelected)="onPageSelected($event)" (onNextSelected)="onPageSelected($event)"
                  (onFirstSelected)="onPageSelected($event)" (onLastSelected)="onPageSelected($event)"
                  (onPageSelected)="onPageSelected($event)">
                </app-pagination>
            </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="delUserModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog delete-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title" id="myModalLabel"><i class="fa fa-warning text-red"></i>&nbsp;Delete User</h4>
      </div>
      <div class="modal-body" style="text-align: left;">
        <span>Are you sure want to delete this user ?<br>
          <label>{{deleteUserModalParams.selectedUserToDelete._id}}</label></span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteUser(deleteUserModalParams)">Delete</button>
        <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="saveUserModal" tabindex="-1" role="dialog"
  aria-labelledby="saveUserModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>{{ saveUserModalParams.header }}</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer">&times;</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>FULL NAME</label>
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="saveUserModalParams.add_user.full_name" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>EMAIL</label>
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="saveUserModalParams.add_user.email" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>PHONE</label>
            <div class="form-group">
              <input type="text" autocomplete="off" class="form-control"
                [(ngModel)]="saveUserModalParams.add_user.phone" />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="saveUserModalParams.add">
          <div class="col-md-12">
            <label>PASSWORD</label>
            <div class="form-group">
              <input type="text" class="form-control" autocomplete="off"
                [(ngModel)]="saveUserModalParams.add_user.password" />
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="saveUser(saveUserModalParams)">
              {{ saveUserModalParams.button_txt }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="resetModal" tabindex="-1" role="dialog"
  aria-labelledby="resetModalabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>RESET PASSWORD</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer">&times;</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>PASSWORD</label>
            <div class="form-group">
              <input type="text" class="form-control" autocomplete="off"
                [(ngModel)]="resetPasswordModalParams.resetPass" />
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="reset(resetPasswordModalParams)">
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>