import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ShipmentService } from '../../services/api/shipment.service';
import { CONSTANTS } from '../../constants';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { DateUtil } from '../../utils/date.utils';
import { NotificationService } from '../../services/notification.service';
import { DatePipe } from '@angular/common';
import { JqueryUtils } from '../../utils/jquery.utils';


@Component({
  selector: 'app-fd-viewshipment',
  templateUrl: './forward-delivery-view.component.html',
  styleUrls: ['./forward-delivery-view.component.scss']
})

export class ForwardDeliveryViewComponent implements OnInit, OnDestroy {

  sess_acc: any;
  token: any;
  searchTxt: any;
  shipments: any = [];
  offset: any = 0;
  totalItems: any = 0;
  shipmentType: any = '';
  selected_tab: any = '';
  selected_tab_option: any = '';
  allow: any = {
    booking: true,
    arrived_at_np_hub: true
  };
  default_tab_txt: any = {
    booking: 'Booking',
    arrived_at_np_hub: 'At Hub',
    intransit: 'Intransit',
    out_for_delivery: 'Out For Delivery',
    delivered: 'Delivered',
    pending: 'Pending',
    rejected: 'Rejected',
    cancel: 'Cancelled'
  };
  fcmNotificationParams: any = { header: '', msg: '' };
  tab_txt: any = {};
  subscriber_id = 'fdv';

  constructor(private dataService: DataService, private shipmentService: ShipmentService,
    private ns: NotificationService, private utilityService: UtilityService, private jq: JqueryUtils,
    private router: Router, private dateUtils: DateUtil, private dp: DatePipe) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    this.calculate_allowed();
    for (let key in this.default_tab_txt) {
      this.tab_txt[key] = this.default_tab_txt[key];
    }
    this.dataService.onShipmentListPageSelected(this.subscriber_id).subscribe(res => {
      this.loadShipments(res.offset);
    });
    this.dataService.onShipmentListTabSelected(this.subscriber_id).subscribe(res => {
      if (!res.search_mode) {
        this.loadShipments(res.offset);
      }
    });
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
    setTimeout(() => {
      let selected: any = this.dataService.getSelectedTab();
      if (!selected) {
        if ([CONSTANTS.USER_TYPES.ADMIN, CONSTANTS.USER_TYPES.SUB_ADMIN, CONSTANTS.USER_TYPES.CLIENT].indexOf(this.sess_acc.user_type) > -1) {
          this.onTabOptionSelected(CONSTANTS.SHIPMENT_TAB.OPTIONS.booking_created, CONSTANTS.SHIPMENT_TAB.BOOKING);
        } else {
          this.onTabOptionSelected(CONSTANTS.SHIPMENT_TAB.OPTIONS.pickup_executive_assigned, CONSTANTS.SHIPMENT_TAB.BOOKING);
        }
      } else {
        this.onTabOptionSelected(selected.tab_option, selected.tab);
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.dataService.setSelectedTab(null);
    this.dataService.offShipmentListPageSelected(this.subscriber_id);
    this.dataService.offShipmentListTabSelected(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }

  calculate_allowed() {
    let uts: any = CONSTANTS.USER_TYPES;
    this.allow = {
      booking: {
        booking_created: [uts.ADMIN, uts.SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
        pickup_executive_assigned: [uts.ADMIN, uts.SUB_ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
        picked_from_warehouse: [uts.ADMIN, uts.SUB_ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
        arrived_at_hub: [uts.ADMIN, uts.SUB_ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
        arrived_at_destination_hub: [uts.ADMIN, uts.SUB_ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      }
    };
  }

  loadShipments(offset: any) {
    if ((this.searchTxt != null) && (this.searchTxt.trim() != "")) {
      this.searchShipment();
    } else {
      this.getShipments(offset, CONSTANTS.ITEM_PER_PAGE);
    }
  }

  refreshShipments() {
    this.loadShipments(this.offset);
  }

  clearSearch() {
    this.searchTxt = ""
    this.loadShipments(0);
  }

  getShipments(offset: any, limit: any) {
    this.offset = offset;
    let input: any = {
      "offset": offset,
      "limit": limit,
      "shipment_type": "forward",
      "state": this.shipmentType
    };
    if ([CONSTANTS.SHIPMENT_TYPE.CANCELLED, CONSTANTS.SHIPMENT_TYPE.DELIVERED, CONSTANTS.SHIPMENT_TYPE.REJECTED].indexOf(this.shipmentType) > -1) {
      var date = new Date();
      input['dt'] = this.dp.transform(date,"yyyy-MM-dd")
    }
    this.shipmentService.getShipments(input).subscribe((response: any) => {
      this.shipments = [];
      this.shipments = response.shipments;
      this.totalItems = response.count;
      this.setTabText();
    });
  }

  searchShipment() {
    if (!this.searchTxt || (this.searchTxt == null) || (this.searchTxt.trim() == '')) return;
    let input = {
      "order_no": this.searchTxt
    };
    this.shipmentService.search(input).subscribe((response: any) => {
      this.shipments = [];
      if ((response.shipment != null) && (response.shipment.shipment_type == CONSTANTS.SHIPMENT_FLOWS.FORWARD)) {
        this.shipments.push(response.shipment);
        this.totalItems = 1;
        let is_booking = [CONSTANTS.SHIPMENT_TYPE.BOOKING_CREATED,
          CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED,
          CONSTANTS.SHIPMENT_TYPE.PICKED_FROM_WAREHOUSE,
          CONSTANTS.SHIPMENT_TYPE.AT_HUB,
          CONSTANTS.SHIPMENT_TYPE.AT_DHUB
        ].indexOf(response.shipment.shipment_status) > -1;
        this.selected_tab = is_booking ? CONSTANTS.SHIPMENT_TAB.BOOKING : response.shipment.shipment_status;
        this.selected_tab_option = "";
        if (is_booking) {
          this.selected_tab_option = response.shipment.shipment_status;
        }
      } else {
        this.ns.showAlert(CONSTANTS.ERROR, "Waybill doesn't exist");
        this.totalItems = 0;
      }
      this.shipmentType = this.getShipmentType();
      this.setTabText();
      this.onTabOptionSelected(this.selected_tab_option, this.selected_tab, true);
    });
  }

  onTabSelected(selected_tab: any, search=false) {
    this.searchTxt = ""
    this.selected_tab = selected_tab;
    this.shipmentType = this.getShipmentType();
    this.dataService.shipmentListTabSelected({offset: 0, count: CONSTANTS.ITEM_PER_PAGE, shipment_type: this.shipmentType, search_mode: search});
  }

  onTabOptionSelected(tab_option: any, selected_tab: any, search: boolean=false) {
    this.searchTxt = ""
    this.selected_tab_option = tab_option;
    this.onTabSelected(selected_tab, search=search);
  }

  setActiveTab(tab: any) {
    if (this.selected_tab == tab) {
      return { active: 1 };
    }
    return { active: 0 };
  }

  setActiveOption(tab_option: any) {
    if (this.selected_tab_option == tab_option) {
      return { active: 1 };
    }
    return { active: 0 };
  }

  setTabText() {
    let txt: any = {
      booking: {
        booking_created: 'Booking',
        pickup_executive_assigned: 'Executive Assigned',
        picked_from_warehouse: 'Warehouse Pickup',
        arrived_at_hub: 'At Hub',
        arrived_at_destination_hub: 'At Destination Hub'
      },
      arrived_at_np_hub: 'At Hub',
      intransit: 'Intransit',
      out_for_delivery: 'Out For Delivery',
      delivered: 'Delivered',
      pending: 'Pending',
      rejected: 'Rejected',
      cancel: 'Cancelled'
    };
    if (this.selected_tab == CONSTANTS.SHIPMENT_TAB.BOOKING) {
      this.tab_txt[this.selected_tab] = `${txt[this.selected_tab][this.selected_tab_option]}(${this.totalItems})`;
    } else {
      this.tab_txt[this.selected_tab] = `${txt[this.selected_tab]}(${this.totalItems})`;
    }
  }

  getShipmentType() {
    let shipment_type: any = "";
    switch(this.selected_tab) {
      case CONSTANTS.SHIPMENT_TAB.BOOKING:
        switch(this.selected_tab_option) {
          case CONSTANTS.SHIPMENT_TAB.OPTIONS.booking_created:
            shipment_type = CONSTANTS.SHIPMENT_TYPE.BOOKING_CREATED;
            break
          case CONSTANTS.SHIPMENT_TAB.OPTIONS.pickup_executive_assigned:
            shipment_type = CONSTANTS.SHIPMENT_TYPE.PE_ASSIGNED;
            break
          case CONSTANTS.SHIPMENT_TAB.OPTIONS.picked_from_warehouse:
            shipment_type = CONSTANTS.SHIPMENT_TYPE.PICKED_FROM_WAREHOUSE;
            break
          case CONSTANTS.SHIPMENT_TAB.OPTIONS.arrived_at_hub:
            shipment_type = CONSTANTS.SHIPMENT_TYPE.AT_HUB;
            break
          case CONSTANTS.SHIPMENT_TAB.OPTIONS.arrived_at_destination_hub:
            shipment_type = CONSTANTS.SHIPMENT_TYPE.AT_DHUB;
            break
        }
        break
      case CONSTANTS.SHIPMENT_TAB.ARRIVED_AT_NP_HUB:
          shipment_type = CONSTANTS.SHIPMENT_TYPE.AT_DHUB;
          break
      case CONSTANTS.SHIPMENT_TAB.INTRANSIT:
        shipment_type = CONSTANTS.SHIPMENT_TYPE.INTRANSIT;
        break
      case CONSTANTS.SHIPMENT_TAB.OUT_FOR_DELIVERY:
        shipment_type = CONSTANTS.SHIPMENT_TYPE.OUT_FOR_DELIVERY;
        break
      case CONSTANTS.SHIPMENT_TAB.DELIVERED:
        shipment_type = CONSTANTS.SHIPMENT_TYPE.DELIVERED;
        break
      case CONSTANTS.SHIPMENT_TAB.PENDING:
        shipment_type = CONSTANTS.SHIPMENT_TYPE.PENDING;
        break
      case CONSTANTS.SHIPMENT_TAB.REJECTED:
        shipment_type = CONSTANTS.SHIPMENT_TYPE.REJECTED;
        break
      case CONSTANTS.SHIPMENT_TAB.CANCELLED:
        shipment_type = CONSTANTS.SHIPMENT_TYPE.CANCELLED;
        break
    }
    return shipment_type;
  }
}
