import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends ApiService {

  public getStore(data: any) {
    return this.getQueryParams(CONSTANTS.API.STORE.GET_STORE, data);
  }
  addStore = (data: any) => {
    return this.post(CONSTANTS.API.STORE.ADD_STORE, data);
  }
  updateStore = (data: any) => {
    return this.put(CONSTANTS.API.STORE.UPDATE_STORE, data);
  }
  public searchStore(data: any) {
    return this.getQueryParams(CONSTANTS.API.STORE.SEARCH_STORE, data);
  }
}
