import { Inject, Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { CONSTANTS } from '../constants';

@Injectable()
export class AppConfigService extends ApiService {

  private configSettings: any = null;

  get settings() {
    return this.configSettings;
  }

  public load(): Promise<any> {
    return new Promise((resolve, reject) => {
     // this.getConfig(CONSTANTS.API.CONFIG_DATA).subscribe((response: any) => {
     //   this.configSettings = response;
        resolve(true);
     // });
    });
  }

  public getCData() {
    return this.get(CONSTANTS.API.CDATA);
  }
}
