import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';

import { NotificationService } from '../../services/notification.service';
import { UtilityService } from '../../services/utility.service';
import { AccountService } from '../../services/api/account.service';
import { CONSTANTS } from '../../constants';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class HeaderComponent implements OnInit {

  menus = [];
  sess_acc: any;
  toggled: any;
  user: any = {
    name: '',
    role: ''
  };

  constructor(private router: Router, private dataService: DataService,
  private ls: AccountService, private ns: NotificationService,
  private us: UtilityService) { }

  ngOnInit() {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.user = {
      name: this.sess_acc.user.full_name,
      role: this.getUsertypeText(this.sess_acc.user_type)
    };
  }

  getUsertypeText(usertype: any) {
    let user_types: any = {
      admin: 'Admin',
      client: 'Client',
      np: 'Network Partner',
      np_fe: 'Field Executive',
      np_sub_admin: 'Delivery Partner',
    };
    return user_types[usertype];
  }

  logout() {
    let isLogout = confirm('Are you sure you want to leave ?');
    if(isLogout === true) {
        this.ls.logout().subscribe((response: any) => {
          this.us.removeAllCookies();
          sessionStorage.clear();
          console.log(sessionStorage)
          this.ns.showAlert(CONSTANTS.SUCCESS, "Logged out successfully");
          this.router.navigate(['/']);
        });
        return true;
    }
    return false;
  }

  route(menu: any) {
    if (menu == "users") {
      this.router.navigate(['/admin', 'users']);
    } else if (menu == "orders") {
      this.router.navigate(['/admin', 'orders']);
    } else if (menu == "settings") {
      this.router.navigate(['/admin', 'settings']);
    } else if (menu == "dashboard") {
      this.router.navigate(['/admin', 'dashboard']);
    } else if (menu == "backtest") {
      this.router.navigate(['/admin', 'backtest']);
    }
  }

  onToggleMenuClicked() {
    this.toggled = (this.toggled == 1) ? 0 : 1;
    this.dataService.toggleMenuClicked({toggled: this.toggled});
  }
}
