<div class="d-flex" id="wrapper" [ngClass]="toggle_class">
    <div class="bg-light border-right" id="sidebar-wrapper">
        <div class="sidebar-heading">
            <img class="img-responsive img-rounded" src="./assets/images/saap.png" alt="User picture">
        </div>
        <div class="list-group list-group-flush">
            <ng-container>
                <a class="list-group-item list-group-item-action bg-light" [ngClass]="get_active_cls('dashboard')" (click)='route("dashboard")'>
                    <i class="fas fa-gauge"></i>
                    <span>DASHBOARD</span>
                </a>
            </ng-container>
            <!-- <ng-container *ngIf="allow.upload">
                <a class="list-group-item list-group-item-action bg-light" [ngClass]="get_active_cls('create')" (click)='route("create")'>
                    <i class="fas fa-upload"></i><span>UPLOAD</span>
                </a>
            </ng-container> -->
            <ng-container *ngIf="allow.shipments">
                <a class="list-group-item list-group-item-action bg-light d-flex justify-content-between"
                data-remote="true" href="#shipments_menu" id="shipments" data-toggle="collapse" data-parent="#shipments_menu">
                    <div>
                        <i class="fas fa-book"></i>
                        <span>SHIPMENT</span>
                    </div>
                    <span class="menu-ico-collapse"><i class="fa fa-chevron-down"></i></span>
                </a>
                <div class="collapse list-group-submenu" id="shipments_menu">
                    <a class="list-group-item sub-item text-center" data-parent="#shipments_menu" [ngClass]="get_active_cls('uploads')" (click)='route("uploads")'>UPLOAD</a>
                    <a class="list-group-item sub-item text-center" data-parent="#shipments_menu" [ngClass]="get_active_cls('api-shipments')" (click)='route("api-shipments")'>API</a>
                </div>
            </ng-container>
            <ng-container *ngIf="allow.fd">
                <a class="list-group-item list-group-item-action bg-light" [ngClass]="get_active_cls('view')" (click)='route("view")'>
                    <i class="fas fa-truck"></i>
                    <span>FORWARD DELIVERY</span>
                </a>
            </ng-container>
            <ng-container *ngIf="allow.ongoing">
                <a class="list-group-item list-group-item-action bg-light" [ngClass]="get_active_cls('view')" (click)='route("view")'>
                    <i class="fas fa-user"></i>
                    <span>ONGOING</span>
                </a>
            </ng-container>
            <ng-container *ngIf="allow.rp">
                <a class="list-group-item list-group-item-action bg-light" [ngClass]="get_active_cls('rpview')" (click)='route("rpview")'>
                    <i class="fas fa-truck-pickup"></i><span>REVERSE PICKUP</span>
                </a>
            </ng-container>
            <ng-container *ngIf="allow.manifest">
                <a class="list-group-item list-group-item-action bg-light d-flex justify-content-between"
                data-remote="true" href="#manifest_menu" id="manifest" data-toggle="collapse" data-parent="#manifest_menu">
                    <div>
                        <i class="fas fa-book"></i>
                        <span>MANIFEST</span>
                    </div>
                    <span class="menu-ico-collapse"><i class="fa fa-chevron-down"></i></span>
                </a>
                <div class="collapse list-group-submenu" id="manifest_menu">
                    <a class="list-group-item sub-item text-center" data-parent="#manifest_menu" [ngClass]="get_active_cls('pickup-manifest')" (click)='route("pickup-manifest")'>PICKUP</a>
                    <a class="list-group-item sub-item text-center" data-parent="#manifest_menu" [ngClass]="get_active_cls('delivery-manifest')" (click)='route("delivery-manifest")' *ngIf="allow.delivery_manifest">DELIVERY</a>
                    <a class="list-group-item sub-item text-center" data-parent="#manifest_menu" [ngClass]="get_active_cls('rp-manifest')" (click)='route("rp-manifest")' *ngIf="allow.rp_pickup_manifest">REVERSE PICKUP</a>
                </div>
            </ng-container>
            <ng-container *ngIf="allow.report">
                <a class="list-group-item list-group-item-action bg-light d-flex justify-content-between"
                data-remote="true" href="#report_menu" id="report" data-toggle="collapse" data-parent="#report_menu">
                    <div>
                        <i class="fas fa-file-lines"></i>
                        <span>REPORT</span>
                    </div>
                    <span class="menu-ico-collapse"><i class="fa fa-chevron-down"></i></span>
                </a>
                <div class="collapse list-group-submenu" id="report_menu">
                    <a class="list-group-item sub-item text-center" data-parent="#report_menu" [ngClass]="get_active_cls('forward-report')" (click)='route("forward-report")' *ngIf="allow.forward_report">FORWARD</a>
                    <a class="list-group-item sub-item text-center" data-parent="#report_menu" [ngClass]="get_active_cls('rp-report')" (click)='route("rp-report")' *ngIf="allow.rp_report">REVERSE</a>
                </div>
            </ng-container>
            <ng-container *ngIf="allow.stores">
                <a class="list-group-item list-group-item-action bg-light" [ngClass]="get_active_cls('stores')" (click)='route("stores")'>
                    <i class="fas fa-building"></i><span>STORES</span>
                </a>
            </ng-container>
            <ng-container *ngIf="allow.users">
                <a class="list-group-item list-group-item-action bg-light" [ngClass]="get_active_cls('users')" (click)='route("users")'>
                    <i class="fas fa-users"></i><span>USERS</span>
                </a>
            </ng-container>
            <ng-container *ngIf="allow.settings">
                <a class="list-group-item list-group-item-action bg-light" [ngClass]="get_active_cls('settings')" (click)='route("settings")'>
                    <i class="fas fa-gear"></i><span>SETTINGS</span>
                </a>
            </ng-container>
            <ng-container *ngIf="allow.reset_pwd">
                <a class="list-group-item list-group-item-action bg-light" [ngClass]="get_active_cls('reset')" (click)='route("reset")'>
                    <i class="fas  fa-key"></i><span>RESET PASSWORD</span>
                </a>
            </ng-container>
            <ng-container>
                <a class="list-group-item list-group-item-action bg-light" (click)="logout()">
                    <i class="fas fa-sign-out-alt"></i><span>LOGOUT</span>
                </a>
            </ng-container>
        </div>
    </div>
    <div id="page-content-wrapper">
        <app-header class="container-buffer"></app-header>
        <router-outlet></router-outlet>
    </div>
</div>