import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 0;
  @Output() onFirstSelected: EventEmitter<any>;
  @Output() onPrevSelected: EventEmitter<any>;
  @Output() onNextSelected: EventEmitter<any>;
  @Output() onLastSelected: EventEmitter<any>;
  @Output() onPageSelected: EventEmitter<any>;
  pages: any = [];
  totalPages: number = 0;
  selectedItemPerPage: number = 20;
  pageNumber: number = 0;
  constructor() {
    this.onFirstSelected = new EventEmitter<any>();
    this.onPrevSelected = new EventEmitter<any>();
    this.onNextSelected = new EventEmitter<any>();
    this.onLastSelected = new EventEmitter<any>();
    this.onPageSelected = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.calculatePages(1);
  }

  calculatePages(page_num: any) {
    let itemsPerPage = (this.itemsPerPage == 0) ? this.selectedItemPerPage : this.itemsPerPage;
    this.totalPages = Math.ceil(this.totalItems / itemsPerPage);
    this.pages = [];
    let pages: any = [];
    let total: any = Math.min(10, this.totalPages);
    let start = 1, end = 10, ellipsis: any = [];
    if (this.totalPages > 10) {
      if ((page_num >= 1) && (page_num <= 7)) {
        start = 1;
        end = start + total - 1;
        ellipsis = [end];
      } else if ((page_num > 7) && (page_num <= (this.totalPages - 7))) {
        start = page_num - 5;
        end = start + total - 1;
        ellipsis = [start, end];
      } else if (page_num > (this.totalPages - 7)) {
        start = this.totalPages - total;
        end = this.totalPages;
        ellipsis = [start];
      }
    } else {
      start = 1;
      end = start + total - 1;
    }
    let disabled: any = false;
    for (let idx = start; idx <= end; idx++) {
      disabled = (ellipsis.indexOf(idx) > -1);
      pages.push({ num: disabled ? '...' : idx, selected: (idx == page_num), disabled: disabled });
      if (idx == page_num) {
        this.pageNumber = idx;
      }
    }
    this.pages = pages;
  }

  firstPageSelected() {
    this.calculatePages(1);
    this.onFirstSelected.emit(this.pageNumber-1);
  }

  previousPageSelected() {
    if (this.pageNumber > 1) {
      this.pageNumber -= 1;
    }
    this.calculatePages(this.pageNumber);
    this.onPrevSelected.emit(this.pageNumber-1);
  }

  nextPageSelected() {
    if (this.pageNumber < this.totalPages) {
      this.pageNumber += 1;
    }
    this.calculatePages(this.pageNumber);
    this.onNextSelected.emit(this.pageNumber-1);
  }

  lastPageSelected() {
    this.calculatePages(this.totalPages);
    this.onLastSelected.emit(this.pageNumber-1);
  }

  pageSelected(page: any) {
    this.calculatePages(page.num);
    this.onLastSelected.emit(this.pageNumber-1);
  }

  ngOnChanges(value: any) {
    if (value.totalItems) {
      this.totalItems = value.totalItems.currentValue;
    }
    if (value.itemsPerPage) {
      this.selectedItemPerPage = value.itemsPerPage.currentValue;
    }
    if (value.pageNumber) {
      this.pageNumber = value.pageNumber.currentValue;
    }
   // this.pageNumber = 0;
    this.calculatePages(this.pageNumber);
  }
}
