<div class="container">
  <div class="row sub-header-buffer">
    <div class="col-md-4">
      <div class="input-group">
          <input type="text" class="form-control h-auto" [(ngModel)]="searchTxt" placeholder="Search by Store Code,Email,Phone" />
          <span class="input-group-btn search-border-style">
              <button class="btn btn-default">
                  <i class="fa fa-xmark text-danger" (click)="clearSearch()"></i>
              </button>
          </span>
      </div>
    </div>
    <div class="col-md-4">
      <button class="btn btn-ecom" (click)="searchStore()">
        Search
      </button>
      <button class="btn btn-ecom ml-2" (click)="openAddStoreModal()" *ngIf="show_add">
        ADD
      </button>
    </div>
  </div>
  <div class="row sub-header-buffer">
    <table class="table ecom-table">
      <thead class="table-header">
        <tr>
          <th>
            <div>NAME</div>
          </th>
          <th>
            <div>PHONE</div>
          </th>
          <th>
            <div>EMAIL</div>
          </th>
          <th>
            <div>ADDRESS</div>
          </th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let store of stores">
          <td>
            <div>{{store?.name}}</div>
          </td>
          <td>
            <div>{{display_store_phones(store?.phone_numbers)}}</div>
          </td>
          <td>
            <div>{{display_store_emails(store?.emails)}}</div>
          </td>
          <td>
            <div *ngFor="let line of display_store_address(store?.address)">{{line}}</div>
          </td>
          <td>
            <i class="fas fa-edit" (click)="openUpdateStoreModal(store)" *ngIf="show_add">
            </i>
            <i class="fas fa-barcode ml-2" (click)="viewStoreBarcodes(store)">
            </i>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="15" class="center-align">
            <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
              <div class="col-md-12 pagenation">
                <app-pagination [totalItems]="totalItems" [itemsPerPage]="count"
                  (onPrevSelected)="onPageSelected($event)" (onNextSelected)="onPageSelected($event)"
                  (onFirstSelected)="onPageSelected($event)" (onLastSelected)="onPageSelected($event)"
                  (onPageSelected)="onPageSelected($event)">
                </app-pagination>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="saveStoreModal" tabindex="-1" role="dialog"
  aria-labelledby="saveStoreModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>{{ saveStoreParams.header }}</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeSaveStoreModal()">&times;</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>NAME</label>
            <div class="form-group">
              <input type="text" [disabled]="saveStoreParams.edit" class="form-control" [(ngModel)]="saveStoreParams.store.name" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>EMAILS</label>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Comma Seperated Email Addresses" [(ngModel)]="saveStoreParams.store.email_str" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>PHONES</label>
            <div class="form-group">
              <input type="text" autocomplete="off" class="form-control" placeholder="Comma Seperated Phone Numbers" [(ngModel)]="saveStoreParams.store.phone_str" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Address</label>
            <div class="form-group">
              <input type="text" autocomplete="off" class="form-control mt-1" [(ngModel)]="saveStoreParams.store.address.line1" />
              <input type="text" autocomplete="off" class="form-control mt-1" [(ngModel)]="saveStoreParams.store.address.line2" />
              <input type="text" autocomplete="off" class="form-control mt-1" [(ngModel)]="saveStoreParams.store.address.line3" />
              <div class="row mt-1">
                <div class="col-md-6">
                  <select class="form-select select-dd" [(ngModel)]="saveStoreParams.selectedStateId" (change)="onSaveStoreStateSelected(saveStoreParams)">
                    <option *ngFor="let state of saveStoreParams.states" [value]="state._id"> {{ state.name }}</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <select class="form-select select-dd" [(ngModel)]="saveStoreParams.selectedLocationId" (change)="onSaveStoreLocationSelected()">
                    <option *ngFor="let location of saveStoreParams.locations" [value]="location._id"> {{ location.name }}</option>
                  </select>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="saveStore(saveStoreParams)">
              {{ saveStoreParams.btn_txt }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
