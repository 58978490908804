import { NotificationService } from '../../services/notification.service';
import { UtilityService } from '../../services/utility.service';
import { AccountService } from '../../services/api/account.service';
import { CONSTANTS } from '../../constants';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {

  sess_acc: any;
  selected_menu: any;
  allow: any = {
    shipments: true,
    fd: true,
    rp: true,
    report: true,
    ongoing: true,
    users: true,
    reset_pwd: true
  };
  toggle_class: any = { 'toggled': 0 };
  subscriber_id: any = 'wrapper';

  constructor(private router: Router, private dataService: DataService,
    private accountService: AccountService, private ns: NotificationService,
    private us: UtilityService) { }

  ngOnInit() {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.calculate_allowed_menus();
    this.dataService.onToggleMenuClicked(this.subscriber_id).subscribe((data: any) => {
      this.toggle_class = data;
    });
  }

  ngOnDestroy() { }

  calculate_allowed_menus() {
    let uts: any = CONSTANTS.USER_TYPES;
    let settings: any = [uts.CLIENT].indexOf(this.sess_acc.user_type) > -1;
    settings = settings && (this.sess_acc.details.api_status == 'active');
    this.allow = {
      shipments: [uts.ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      fd: [uts.ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN].indexOf(this.sess_acc.user_type) > -1,
      manifest: [uts.ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      delivery_manifest: [uts.ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN].indexOf(this.sess_acc.user_type) > -1,
      rp_pickup_manifest: [uts.ADMIN, uts.NETWORK_PARTNER, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      rp: [uts.ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      report: [uts.ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      forward_report: [uts.ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      rp_report: [uts.ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      stores: [uts.ADMIN, uts.CLIENT, uts.NETWORK_PARTNER].indexOf(this.sess_acc.user_type) > -1,
      users: [uts.ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN].indexOf(this.sess_acc.user_type) > -1,
      settings: settings,
      ongoing: [uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      reset_pwd: false
    };
  }

  route(currentMenu: any) {
    this.selected_menu = currentMenu;
    let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
    this.router.navigate([root, currentMenu]);
  }

  logout() {
    this.accountService.logout().subscribe((response: any) => {
      this.us.removeAllCookies();
      sessionStorage.clear();
      localStorage.clear();
      this.ns.showAlert(CONSTANTS.SUCCESS, "Logged out successfully");
      this.router.navigate(['/']);
    });
  }

  get_active_cls(menu: any) {
    if (this.selected_menu == menu) {
      // return { active: 1 };
    }
    return { active: 0 };
  }
}
