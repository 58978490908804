<div class="container px-0">
    <div class="row sub-header-buffer">
        <div class="col-6">
            <div class="row">
                <div class="col">
                    <div class="font-weight-bold">Field Executive</div>
                    <hr class="hr-adjusted"/>
                    <div class="mt-1">{{ manifest?.fe?.user?.full_name }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row sub-header-buffer">
        <div class="col-md-12">
            <table class="table ecom-table">
                <thead class="table-header">
                    <tr>
                        <th>
                            <div>Date</div>
                        </th>
                        <th>
                            <div>AWB No</div>
                            <div>Store</div>
                        </th>
                        <th>
                            <div>Total Box</div>
                            <div>Total Weight</div>
                        </th>
                        <th *ngIf="tableParams.show_actions">
                            <div>Actions</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let shipment of shipments">
                        <td>
                            <div>{{shipment.created_date}}</div>
                        </td>
                        <td>
                            <div>{{shipment.details?.bar_code.toUpperCase()}}</div>
                            <div>{{shipment.details?.store.toUpperCase()}}</div>
                        </td>
                        <td>
                            <div>{{shipment.details?.total_box}}</div>
                            <div>{{shipment.details?.total_weight}}</div>
                        </td>
                        <td *ngIf="tableParams.show_actions">
                            <i class="fas fa-barcode pl-2" (click)="showBarcodes(shipment)"></i>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="15" class="center-align">
                            <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
                                <div class="col-md-12 pagenation">
                                    <app-pagination [totalItems]="totalItems"
                                        (onPrevSelected)="onPageSelected($event)" (onNextSelected)="onPageSelected($event)"
                                        (onFirstSelected)="onPageSelected($event)" (onLastSelected)="onPageSelected($event)"
                                        (onPageSelected)="onPageSelected($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<div class="modal fade mt-0" id="rpManifestSLBarcodeModal" tabindex="-1" aria-labelledby="rpManifestSLBarcodeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content rounded-0">
      <div class="modal-body">
        <div class="row print-margin" *ngFor="let barcode of viewBarcodeParams.barcodes">
          <div class="col-12">
            <div class="align-barcode d-flex w-bheader">
              <label for="barcode" class="top_text barcode-label">SAAP Logistics</label>
              <label for="barcode" class="top_text barcode-label ml-auto">{{ barcode.obj.details.store }}</label>
            </div>
            <ngx-barcode6 [bc-format]="'CODE128'" [bc-value]="barcode.barcode" [bc-width]="3" [bc-height]="100"
              [bc-text-align]="'center'" [bc-font-size]="10" [bc-display-value]="false"></ngx-barcode6>
            <div class="align-barcode d-flex flex-column">
              <label class="barcode-label">AWB : {{ barcode.barcode }}</label>
              <label class="barcode-label">Box : {{ barcode.box_no }}/{{ barcode.obj.details.total_box }}</label>
              <label class="barcode-label">Weight : {{ barcode.obj.details.total_weight }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBarcodeModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="printBarcodes(viewBarcodeParams)">Print</button>
      </div>
    </div>
  </div>
</div>
