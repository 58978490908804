<div class="row sub-header-buffer">
  <div class="col-md-12">
    <button type="button" class="btn btn-ecom px-5 float-right" *ngIf="any_selected" (click)="scaninShipments(calculateSelectedShipments())">
      Scanout
    </button>
</div>
</div>
<div class="row sub-header-buffer">
  <div class="col-md-12">
    <table class="table ecom-table">
      <thead class="table-header">
        <tr>
          <th class="text-center align-middle p-0" *ngIf="tableParams.show_multi_selection">
            <input type="checkbox" [checked]="all_selected" (change)="onAllShipmentsSelected($event)"
              aria-label="Checkbox for selecting all shipments">
          </th>
          <th>
            <div>AWB No</div>
            <div>Store</div>
          </th>
          <th>
            <div>Booking Time</div>
            <div>{{ get_time_header() }}</div>
          </th>
          <th *ngIf="tableParams.show_state">
            <div>Origin</div>
            <div>Destination</div>
          </th>
          <th *ngIf="tableParams.show_np">
            <div>Partner Name</div>
            <div>Partner Phone</div>
          </th>
          <th *ngIf="tableParams.show_fe">
            <div>Executive Name</div>
            <div>Executive Phone</div>
          </th>
          <th *ngIf="tableParams.show_cd">
            <div>Coloader Name</div>
            <div>Coloader Phone</div>
            <div>Coloader City</div>
          </th>
          <th>
            <!-- <div *ngIf="tableParams.show_scanned">Scanned Count</div> -->
            <div>Invoice Count</div>
          </th>
          <th>
            <div>Total Box</div>
            <div>Total Weight</div>
          </th>
          <th *ngIf="tableParams.show_remarks">
            <div>Remarks</div>
          </th>
          <th *ngIf="tableParams.show_pod">
            <div>Sign</div>
            <div>Photo</div>
          </th>
          <th class="text-center" *ngIf="tableParams.has_actions">
            <div>Actions</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let shipment of shipments">
          <td class="text-center align-middle p-0" *ngIf="tableParams.show_multi_selection">
            <input type="checkbox" [checked]="!!shipment.selected" (change)="onShipmentSelected(shipment, $event)"
              aria-label="Checkbox for selecting shipment">
          </td>
          <td>
            <div>{{shipment.details.bar_code}}</div>
            <div>{{shipment.details.store}}</div>
          </td>
          <td>
            <div>{{shipment.created_date}}</div>
            <div>{{ get_state_time(shipment) }}</div>
          </td>
          <td *ngIf="tableParams.show_state">
            <div>{{shipment.details.pickup_city.toUpperCase()}}</div>
            <div>{{shipment.details.delivery_city.toUpperCase()}}</div>
          </td>
          <td *ngIf="tableParams.show_np">
            <div>{{ get_np_details(shipment, 'full_name')}}</div>
            <div>{{ get_np_details(shipment, 'phone')}}</div>
          </td>
          <td *ngIf="tableParams.show_fe">
            <div>{{ get_fe_details(shipment, 'full_name')}}</div>
            <div>{{ get_fe_details(shipment, 'phone')}}</div>
          </td>
          <td *ngIf="tableParams.show_cd">
            <div>{{shipment.coloader?.name}}</div>
            <div>{{shipment.coloader?.phone}}</div>
            <div>{{shipment.coloader?.city}}</div>
          </td>
          <td>
            <a [routerLink]="['/invoicedetails', {'id': shipment.id,'waybill':shipment.details.order_no, 'token': token}]"
              routerLinkActive="active" target="_blank">
              {{shipment.details.invoice_count}}
            </a>
          </td>
          <td>
            <div>
              <span *ngIf="tableParams.show_scanned">{{get_count_by_state(shipment)}} / </span>
              {{shipment.details.total_box}}
            </div>
            <div>{{shipment.details.total_weight}}</div>
          </td>
          <td *ngIf="tableParams.show_remarks">
            <div>{{shipment.details?.rejected?.rej_reason}}</div>
          </td>
          <td *ngIf="tableParams.show_pod">
            <div>
              <a href="javascript:void(0)" *ngIf="has_pod(shipment, 'sign')" (click)="downloadSign(shipment)">View Sign</a>
              <div *ngIf="!has_pod(shipment, 'sign')">NO Sign</div>
            </div>
            <div>
              <a href="javascript:void(0)" *ngIf="has_pod(shipment, 'pod')" (click)="downloadPOD(shipment)">View Photo</a>
              <div *ngIf="!has_pod(shipment, 'pod')">NO Photo</div>
            </div>
          </td>
          <td class="text-center" *ngIf="tableParams.has_actions">
            <div class="row">
              <div class="col">
                <i class="fas fa-eye text-primary mx-1" (click)="showOTP(shipment)"
                *ngIf="tableParams.show_otp"></i>
                <i class="fas fa-user-plus mx-1" (click)="openAssignSLFEModal(shipment)"
                  *ngIf="tableParams.assign_fe"></i>
                <i class="fas fa-circle-xmark text-danger mx-1" (click)="openConfirmationModal(shipment, 'cancel_shipment')"
                  *ngIf="tableParams.cancel"></i>
                <i class="fas fa-arrow-right-from-bracket mx-1" (click)="openScanoutModal(shipment)"
                  *ngIf="tableParams.show_scanout"></i>
                <i class="fas fa-arrow-right-from-bracket mx-1" (click)="openScaninModal(shipment)"
                  *ngIf="tableParams.show_scanin"></i>
                <i class="fas fa-barcode mx-1" (click)="showBarcodes(shipment)" *ngIf="tableParams.barcode"></i>
                <i class="fas fa-truck-medical mx-1" (click)="openColoaderModal(shipment)"
                  *ngIf="tableParams.show_cd"></i>
                <i class="fas fa-motorcycle mx-1" *ngIf="show_ofd(shipment)" (click)="moveToOFD(shipment)"></i>
                <i class="fas fa-user-plus mx-1" *ngIf="show_assign(shipment)"
                  (click)="openAssignSLDFEModal(shipment)" [ngClass]="assign_class(shipment)"></i>
                <i class="fas fa-user-xmark mx-1 text-danger" *ngIf="show_unassign(shipment)"
                  (click)="openConfirmationModal(shipment, 'unassign_de')" ></i>
                <i class="fas fa-download mx-1" *ngIf="show_pod_download(shipment)"
                  (click)="downloadShipmentPOD(shipment)" ></i>
                <a href="https://www.google.com/maps/search/?api=1&query={{get_location(shipment)}}" target="_blank" class="" *ngIf="show_location(shipment)">
                  <i class="fas fa-map-marker-alt mx-1 text-primary"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="15" class="center-align">
            <div class="row sub-header-buffer" *ngIf="totalItems > 0">
              <div class="col-md-12 pagenation">
                <app-pagination [totalItems]="totalItems" (onPrevSelected)="onPageSelected($event)"
                  (onNextSelected)="onPageSelected($event)" (onFirstSelected)="onPageSelected($event)"
                  (onLastSelected)="onPageSelected($event)" (onPageSelected)="onPageSelected($event)">
                </app-pagination>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="assignSLFEModal" tabindex="-1" role="dialog"
  aria-labelledby="assignSLFELabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>Assign Executive</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close" style="cursor: pointer"
              (click)="closeAssignSLFEModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label>Network Partners</label>
            <div class="form-group">
              <select class="form-select select-dd" [(ngModel)]="assignParams.selNpId"
                (change)="onNPSelected(assignParams, 'assign_fe')">
                <option *ngFor="let np of assignParams.nps" [value]="np.user.user_id"> {{ np.user.full_name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label>Pickup Executives</label>
            <div class="form-group">
              <select class="form-select select-dd" [(ngModel)]="assignParams.selExecId"
                (change)="onFESelected(assignParams, 'assign_fe')">
                <option *ngFor="let fe of assignParams.fes" [value]="fe.user.user_id"> {{ fe.user.full_name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="assignExecutive(assignParams)">
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" [attr.id]="confirmModalName" tabindex="-1" role="dialog"
  aria-labelledby="confirmModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>{{ confirmationParams.header }}</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close" style="cursor: pointer"
              (click)="closeConfirmModal(confirmationParams)">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
          <label>{{ confirmationParams.msg }}</label>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-right">
            <button class="btn btn-ecom" (click)="closeConfirmModal(confirmationParams)">
              Cancel
            </button>
            <button class="btn btn-ecom ml-2" (click)="confirm(confirmationParams)">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade mt-0" [attr.id]="slBarcodeModalName" tabindex="-1" aria-labelledby="slBarcodeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content rounded-0">
      <div class="modal-body" id="print-section">
        <div class="row print-margin" *ngFor="let barcode of viewBarcodeParams.barcodes">
          <div class="col-12">
            <div class="align-barcode d-flex w-bheader">
              <label for="barcode" class="top_text barcode-label">SAAP Logistics</label>
              <label for="barcode" class="top_text barcode-label ml-auto">{{ barcode.obj.details.store }}</label>
            </div>
            <ngx-barcode6 [bc-format]="'CODE128'" [bc-value]="barcode.barcode" [bc-width]="3" [bc-height]="100"
              [bc-text-align]="'center'" [bc-font-size]="10" [bc-display-value]="false"></ngx-barcode6>
            <div class="align-barcode d-flex flex-column">
              <label class="barcode-label">AWB : {{ barcode.barcode }}</label>
              <label class="barcode-label">Ref No : {{ barcode.obj.order_no }}</label>
              <label class="barcode-label">Box : {{ barcode.box_no }}/{{ barcode.obj.details.total_box }}</label>
              <label class="barcode-label">Weight : {{ barcode.obj.details.total_weight }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBarcodeModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="printBarcodes(viewBarcodeParams.barcodes)">Print</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" [attr.id]="coloaderModalName" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Coloader Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeColoaderModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row pt-3">
          <div class="col-md-12">
            <label>Name</label>
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="coloaderParams.name" />
            </div>
          </div>
          <div class="col-md-12">
            <label>Phone</label>
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="coloaderParams.phone" />
            </div>
          </div>
          <div class="col-md-12">
            <label>City</label>
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="coloaderParams.city" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeColoaderModal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="addColoaderDetails(coloaderParams)">Add</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="scanoutModal" tabindex="-1" role="dialog"
  aria-labelledby="scanoutModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>Scanout</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close" style="cursor: pointer"
              (click)="closeScanoutModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3" *ngIf="scanoutParams.type == 'np'">
          <div class="col-md-12">
            <label>Select Network Partner</label>
            <div class="form-group">
              <select class="form-select select-dd" [(ngModel)]="scanoutParams.np_id"
                (change)="onNPSelected(scanoutParams, 'scan_out')">
                <option *ngFor="let np of scanoutParams.nps" [value]="np.user.user_id"> {{ np.user.full_name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col text-right">
            <button class="btn btn-ecom mx-1" (click)="closeAssignSLFEModal()">
              Cancel
            </button>
            <button class="btn btn-ecom mx-1" (click)="scanout(scanoutParams)">
              Scanout
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" [attr.id]="assignSLDFEModalName" tabindex="-1"
  role="dialog" aria-labelledby="assignSLDFELabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>Assign Delivery Executive</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close" style="cursor: pointer"
              (click)="closeAssignSLDFEModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label>Delivery Executives</label>
            <div class="form-group">
              <select class="form-select select-dd" [(ngModel)]="assignParams.selExecId"
                (change)="onFESelected(assignParams, 'assign_de')">
                <option *ngFor="let fe of assignParams.fes" [value]="fe.user.user_id"> {{ fe.user.full_name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="assignDeliveryExecutive(assignParams)">
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" data-keyboard="false" [attr.id]="errorDisplayModalName" tabindex="-1"
  role="dialog" aria-labelledby="errorDisplayModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>{{ errDisplayParams.header }}</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close" style="cursor: pointer"
              (click)="closeErrorDisplayModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
          <table class="table ecom-table">
            <thead class="table-header">
              <tr>
                <th>
                  <div>Barcode</div>
                </th>
                <th>
                  <div>Message</div>
                </th>
                <th>
                  <div>Status</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let error of errDisplayParams.errors">
                <td>
                  <div>{{error.barcode}}</div>
                </td>
                <td>
                  <div>{{error.msg}}</div>
                </td>
                <td>
                  <div class="row">
                    <div class="col">
                      <i class="fas fa-check text-success mx-1" *ngIf="error.success"></i>
                      <i class="fas fa-circle-xmark text-danger mx-1" *ngIf="!error.success"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>