import { NotifierService } from 'angular-notifier';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

/**
 * Notification Type:
 * default
 * info
 * success
 * warning
 * error
 */
export class NotificationService {

    constructor(private notifierService: NotifierService) { }

    /**
     * Show Notification
     * @param {string} type Notification Type
     * @param {string} text Alert Message
     */
    showAlert(type: string, text: string) {
        this.notifierService.notify(type, text);
    }

    /**
	 * Hide oldest notification
	 */
	public hideOldestNotification(): void {
		this.notifierService.hideOldest();
	}

	/**
	 * Hide newest notification
	 */
	public hideNewestNotification(): void {
		this.notifierService.hideNewest();
	}

	/**
	 * Hide all notifications at once
	 */
	public hideAllNotifications(): void {
		this.notifierService.hideAll();
	}

	/**
	 * Show a specific notification (with a custom notification ID)
	 *
	 * @param {string} type    Notification type
	 * @param {string} message Notification message
	 * @param {string} id      Notification ID
	 */
	public showSpecificNotification( type: string, message: string, id: string ): void {
		this.notifierService.show( {
			id,
			message,
			type
		} );
	}

	/**
	 * Hide a specific notification (by a given notification ID)
	 *
	 * @param {string} id Notification ID
	 */
	public hideSpecificNotification( id: string ): void {
		this.notifierService.hide( id );
	}
}