import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CONSTANTS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private cookieService: CookieService) { }

  public setObject(key: string, obj: any) {
    if (key == CONSTANTS.COOKIE.HEADER) {
      this.cookieService.set(CONSTANTS.COOKIE.ISLOGGEDIN, true.toString());
    }
    this.cookieService.set(key, JSON.stringify(obj));
  }

  public getObject(key: string) {
    let obj = this.cookieService.get(key);
    if (obj != null && obj != undefined && obj.length > 0) {
      return JSON.parse(obj);
    }
  }

  public removeAllCookies() {
    this.cookieService.deleteAll();
  }

  public removeCookie(key: any) {
    this.cookieService.delete(key);
  }

  public isLoggedIn() {
    return this.getObject(CONSTANTS.COOKIE.ISLOGGEDIN);
  }

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }
}
