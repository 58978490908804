import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class StateService extends ApiService {

  public getStates() {
    return this.get(CONSTANTS.API.STATE.GET_STATES);
  }
}
