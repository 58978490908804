import { Component } from '@angular/core';
import { ShipmentService } from '../../../services/api/shipment.service';
import { DataService } from '../../../services/data.service';
import { NotificationService } from '../../../services/notification.service';
import { UtilityService } from '../../../services/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JqueryUtils } from '../../../utils/jquery.utils';
import { CONSTANTS } from '../../../constants';
import { StateService } from '../../../services/api/state.service';
import { DistrictService } from '../../../services/api/district.service';

@Component({
  selector: 'app-api-shipment-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {

  sess_acc: any;
  token: any;
  offset: any;
  sud: any;
  upload_id: any;

  states: any = [];
  shipments: any = [];
  totalItems: any = 0;
  tableParams: any = {};
  addDistrictParams: any = {};
  updateDistrictTagParams: any = {};
  barcodes: any = [];
  subscriber_id: any = 'fdl-list';

  constructor(private districtService: DistrictService, private shipmentService: ShipmentService,
    private dataService: DataService, private ns: NotificationService,
    private utilityService: UtilityService, private stateService: StateService, public route: ActivatedRoute,
    private router: Router, private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    this.route.queryParams.subscribe(params => {
      this.upload_id = params['upload_id'];
    });
    if (!this.upload_id) {
      this.navigateToUpload();
    } else {
      this.getStates();
      this.loadShipments();
    }
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  navigateToUpload() {
    let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
    this.router.navigate([root, CONSTANTS.ROUTES.API_SHIPMENTS]);
  }

  loadShipments() {
    let ip: any = {
      upload_id: this.upload_id,
      type: 'unprocessed'
    };
    this.shipmentService.getUploadShipments(ip).subscribe((response: any) => {
      this.sud = response.sud;
      this.shipments = response.shipments;
      this.tableParams = {
        show_actions: true
      };
      if (this.shipments.length == 0) {
        let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
        this.router.navigate([root, CONSTANTS.ROUTES.API_SHIPMENTS]);
      }
    });
  }

  getStates() {
    this.states = [];
    this.stateService.getStates().subscribe((response: any) => {
      let states: any = [{ full_name: "Select", _id: 0 , tag:"" }];
      response.states.forEach((state: any, idx: any) => {
        states.push({ full_name: state, _id: idx+1, tag: state });
      });
      this.states = states;
    });
  }

  getDistricts(state: any, cb: any = null) {
    this.districtService.getDistricts({state: state.tag}).subscribe((response: any) => {
      let districts: any = [{ full_name: "Select", _id: 0 , tag:"" }];
      response.districts.forEach((district: any, idx: any) => {
        districts.push({ full_name: district, _id: idx+1, tag: district });
      });
      if (cb) {
        cb(districts);
      }
    });
  }

  processSud() {
    let ip: any = { sud_id: this.sud._id };
    this.shipmentService.processUpload(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Shipment Upload Location Processed Successfully.");
      this.loadShipments();
    });
  }

  openAddDistrictModal(shipment: any) {
    this.addDistrictParams = { states: this.states, selectedState: this.states[0]._id, district: shipment.details.delivery_city };
    this.jq.openModal('addDistrictModal');
  }

  openAddTagModal(shipment: any) {
    this.updateDistrictTagParams = { states: this.states, selectedState: this.states[1]._id, selectedDistrict: '', tag: shipment.details.delivery_city };
    this.onStateSelected('update_tag', this.updateDistrictTagParams);
    this.jq.openModal('updateDistrictTagModal');
  }

  addDistrict(params: any) {
    let state: any = this.getStateById(params.selectedState);
    if (!state) {
      this.ns.showAlert(CONSTANTS.ERROR, "Please select the state.");
      return;
    }
    let ip: any = {
      name: params.district,
      state: state.tag,
      tags: [params.district.toLowerCase()],
    };
    this.districtService.addDistrict(ip).subscribe((response: any) => {
      this.addDistrictParams = { };
      this.jq.closeModal('addDistrictModal');
      this.processSud();
    });
  }

  updateDistrictTag(params: any) {
    let state: any = this.getStateById(params.selectedState);
    if (!state) {
      this.ns.showAlert(CONSTANTS.ERROR, "Please select the state.");
      return;
    }
    let district: any = this.getDistrictById(params.selectedDistrict, params.districts);
    if (!district) {
      this.ns.showAlert(CONSTANTS.ERROR, "Please select the district.");
      return;
    }
    let ip: any = {
      state: state.tag,
      dist: district.tag,
      tags: [params.tag.toLowerCase()],
    };
    this.districtService.updateDistrictTag(ip).subscribe((response: any) => {
      this.updateDistrictTagParams = { };
      this.jq.closeModal('updateDistrictTagModal');
      this.processSud();
    });
  }

  showBarcodes(shipment: any) {
    let barcodes: any = [];
    for (let iidx = 0; iidx < shipment.details.invoice_count; iidx++) {
      barcodes.push({
        index: iidx,
        barcode: shipment.details.bar_code,
        shipment_id: shipment.id,
        label: `Slide ${iidx}`
      });
    }
    this.barcodes = barcodes;
    this.jq.openModal('manifestUSLBarcodeModal');
  }

  closeAddDistrictModal() {
    this.addDistrictParams = { };
    this.jq.closeModal('addDistrictModal');
  }

  closeUpdateDistrictTagModal() {
    this.updateDistrictTagParams = { };
    this.jq.closeModal('updateDistrictTagModal');
  }

  closeBarcodeModal() {
    this.barcodes = [];
    this.jq.closeModal('manifestUSLBarcodeModal');
  }

  printBarcodes() {
    this.barcodes = [];
    this.jq.closeModal('manifestUSLBarcodeModal');
  }

  onStateSelected(from: any, params: any) {
    if (from == 'update_tag') {
      let state: any = this.getStateById(params.selectedState);
      if (state) {
        this.getDistricts(state, (districts: any) => {
          params.districts = districts;
          params.selectedDistrict = districts[1]._id;
        });
      }
    }
  }

  onDistrictSelected() { }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.loadShipments();
  }

  getDistrictById(st_id: any, districts: any = []) {
    let district: any = null;
    for (let idx = 0; idx < districts.length; idx++) {
      if ((districts[idx]._id != 0) && (districts[idx]._id == st_id)) {
        district = districts[idx];
        break;
      }
    }
    return district;
  }

  getStateById(st_id: any) {
    let state: any = null;
    for (let idx = 0; idx < this.states.length; idx++) {
      if ((this.states[idx]._id != 0) && (this.states[idx]._id == st_id)) {
        state = this.states[idx];
        break;
      }
    }
    return state;
  }
}
