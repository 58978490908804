<nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
    <button class="btn" id="menu-toggle" (click)="onToggleMenuClicked()">
        <div class="hamburger"></div>
        <div class="hamburger"></div>
        <div class="hamburger"></div>
    </button>
    <div class="collapse navbar-collapse" id="navbarContent">
        <!-- <div class="navbar-right pull-left">
            <div class="dropdown d-inline-block">
                <button type="button" class="btn header-item bg-soft-light border-start border-end"
                id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span style="color:black">{{username}}</span>
                <i style="color:black" class="fas fa-caret-down"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end"> -->
                <!-- item-->
                <!-- <a class="dropdown-item" href="#" (click)="logout()"><i
                    class="mdi mdi-logout font-size-16 align-middle me-1"></i> Logout</a>
                </div>
            </div>
        </div> -->
        <ul class="navbar-nav mr-auto"></ul>
        <form class="form-inline my-2 my-lg-0">
            <div>
                <div class="text-right">Hello {{user.name}}</div>
                <div class="text-right">Logged in as {{user.role}}</div>
            </div>
          </form>
    </div>
</nav>