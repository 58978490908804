import { Component, Input, OnInit } from '@angular/core';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-fcm-notification',
  templateUrl: './fcm-notification.component.html',
  styleUrls: ['./fcm-notification.component.scss']
})
export class FCMNotificationComponent implements OnInit {

  @Input('params')
  notification_params: any = {
    header: '',
    msg: ''
  };

  constructor(private jq: JqueryUtils) { }

  ngOnInit(): void { }

  closeModal() {
    this.jq.closeModal('fcmNotificationModal');
  }
}
