<div class="modal fade" data-backdrop="static" data-keyboard="false" id="fcmNotificationModal" tabindex="-1" role="dialog"
  aria-labelledby="fcmNotificationModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>{{ notification_params.header }}</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
            <label>{{ notification_params.msg }}</label>
        </div>
        <div class="row sub-header-buffer">
          <button class="btn btn-block btn-ecom ml-2" (click)="closeModal()">
              OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
