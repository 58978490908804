import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/api/account.service';
import { BarcodeService } from '../../services/api/barcode.service';
import { NotificationService } from '../../services/notification.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { CONSTANTS } from '../../constants';
import { JqueryUtils } from '../../utils/jquery.utils';
import { PrintService } from '../../services/api/print.service';
import { DateUtil } from '../../utils/date.utils';

@Component({
  selector: 'app-store-barcode-list',
  templateUrl: './store-barcode-list.component.html',
  styleUrls: ['./store-barcode-list.component.scss']
})

export class StoreBarcodeListComponent implements OnInit, OnDestroy {

  sess_acc: any;
  token: any;
  offset: any;

  store: any = {};
  store_barcodes: any = [];
  clients: any = [];
  tableParams: any = {};
  viewBarcodeParams: any = {
    barcodes: []
  };
  saveBarcodeModalParams: any = {
    header: '',
    count: 0,
    btn_txt: '',
    selectedClientId: ''
  };
  fcmNotificationParams: any = { header: '', msg: '' };
  subscriber_id: any = 'sbl';

  constructor(private accountService: AccountService, private barcodeService: BarcodeService, private dataService: DataService,
    private ns: NotificationService, private printService: PrintService, private utilityService: UtilityService, public route: ActivatedRoute,
    private router: Router, private dateUtils: DateUtil, private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    this.route.queryParams.subscribe(params => {
      this.store = params['store'];
    });
    if (!this.store) {
      let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
      this.router.navigate([root, CONSTANTS.ROUTES.STORES]);
    } else {
      this.loadGeneratedBarcodes();
      this.loadClients();
    }
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  loadClients() {
    let input = { };
    this.accountService.getClients(input).subscribe((response: any) => {
      let clients: any = [{ user: { user_id: "-1", full_name: "Select" } }];
      response.accounts.forEach((acc: any) => {
        clients.push(acc);
      });
      this.clients = clients;
    });
  }

  loadGeneratedBarcodes() {
    let ip: any = {
      store: [this.store],
    };
    this.barcodeService.getStoreBarcodeList(ip).subscribe((response: any) => {
      let bc_stores: any = [];
      response.barcode_stores.forEach((bs: any) => {
        bc_stores.push(bs);
      });
      this.store_barcodes = bc_stores;
      this.tableParams = {
        show_actions: true
      };
    });
  }

  showBarcodes(store_barcode: any) {
    let barcodes: any = [];
    let start: any = parseInt(store_barcode.start), end: any = parseInt(store_barcode.end);
    for (let iidx = start; iidx <= end; iidx++) {
      barcodes.push({
        index: iidx,
        barcode: `${store_barcode.pre_key}${iidx}`,
        obj: store_barcode,
        label: `Slide ${iidx}`
      });
    }
    this.viewBarcodeParams = {
      barcodes: barcodes
    };
    this.jq.openModal('storeBarcodeModal');
  }

  closeBarcodeModal() {
    this.viewBarcodeParams = { barcodes: [] };
    this.jq.closeModal('storeBarcodeModal');
  }

  printBarcodes(params: any) {
    let barcodes: any = params.barcodes;
    let codes: any = [];
    for (let idx = 0; idx < barcodes.length; idx++) {
      codes.push({
        barcode: barcodes[idx].barcode,
        box_no: '1',
        name: barcodes[idx].obj.client?.user?.full_name,
        obj: {
          order_no: '',
          mode: '',
          store: barcodes[idx].obj.store,
          total_weight: ''
        }
      });
    }
    let ip: any = { codes };
    this.printService.printBarcodes(ip).subscribe((response: any) => {
      this.viewBarcodeParams = { barcodes: [] };
      this.jq.closeModal('storeBarcodeModal');
    });
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.loadGeneratedBarcodes();
  }

  openAddBarcodeModal() {
    this.saveBarcodeModalParams = {
      header: 'Generate Barcodes',
      count: 1,
      btn_txt: 'Generate',
      store: this.store,
      show_client: (this.sess_acc.user_type != 'client'),
      selectedClientId: this.clients[0].user.user_id
    };
    this.jq.openModal('saveBarcodeModal');
  }

  closeSaveBarcodeModal() {
    this.saveBarcodeModalParams = {
      header: '',
      count: 0,
      btn_txt: '',
      selectedClientId: ''
    };
    this.jq.closeModal('saveBarcodeModal');
  }

  saveBarcode(params: any) {
    let client_id: any;
    if (params.show_client) {
      if (params.selectedClientId == "-1") {
        this.ns.showAlert(CONSTANTS.ERROR, 'Please select client');
        return;
      }
      client_id = params.selectedClientId;
    } else {
      client_id = this.sess_acc.user.user_id;
    }
    let ip: any = { client: client_id, count: params.count, store: params.store };
    this.barcodeService.createBarcodes(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, 'Barcodes Generated successfully.');
      this.loadGeneratedBarcodes();
      this.jq.closeModal('saveBarcodeModal');
    });
  }

  onSaveBarcodeClientSelected() { }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }
}
