import { Injectable } from "@angular/core";

declare var $: any;

@Injectable({
    providedIn: 'root'
})

export class JqueryUtils {

    constructor() { }

    openModal(modalID: string) {
        $('#' + modalID).modal('show');
    }

    closeModal(modalID: string) {
        $('#' + modalID).modal('hide');
    }
}