import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReportComponent } from './components/report/report.component';
import { RPReportComponent } from './components/rp-report/rp-report.component';

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../../../../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { NotifierOptions, NotifierModule } from 'angular-notifier';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AuthInterceptor } from './services/auth.interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxPrintModule } from 'ngx-print';
import { AppConfigService } from './services/appconfig.service';

import { AppComponent } from './components/app.component';
import { LoginComponent } from './components/login/login.component';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { HeaderComponent } from './components/header/header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ForwardDeliveryViewComponent } from './components/forward-delivery-view/forward-delivery-view.component';
import { LoadDetailsComponent } from './components/loaddetails/loaddetails.component';
import { UserComponent } from './components/user/user.component';
import { SettingsComponent } from './components/settings/settings.component';
import { StoreComponent } from './components/store/store.component';
import { CreateShipmentComponent } from './components/createshipment/createshipment.component';
import { ListComponent as CSListComponent } from './components/createshipment/list/list.component';
import { APIShipmentsComponent } from './components/api-shipments/api-shipments.component';
import { ListComponent as APIListComponent } from './components/api-shipments/list/list.component';
import { ClientComponent } from './components/client/client.component';
import { ChangePasswordComponent } from './components/b2b/b2b_changepassword/changepassword.component';
import { DataService } from './services/data.service';
import { ListComponent } from './components/forward-delivery-view/list/list.component';
import { ManifestComponent } from './components/manifest/manifest.component';
import { ManifestShipmentListComponent } from './components/manifest-shipment-list/manifest-shipment-list.component';
import { RPManifestComponent } from './components/rp-manifest/rp-manifest.component';
import { RPManifestShipmentListComponent } from './components/rp-manifest-shipment-list/rp-manifest-shipment-list.component';
import { ReversePickupViewComponent } from './components/reverse-pickup-view/reverse-pickup-view.component';
import { RpListComponent } from './components/reverse-pickup-view/rp-list/rp-list.component';
import { StoreBarcodeListComponent } from './components/store-barcode-list/store-barcode-list.component';
import { PickupManifestComponent } from './components/pickup-manifest/pickup-manifest.component';
import { FCMNotificationComponent } from './components/fcm-notification/fcm-notification.component';
import { MessagingService } from './services/messaging.service';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: { position: 'right', distance: 12 },
    vertical: { position: 'top', distance: 12, gap: 10 }
  },
  theme: 'material',
  behaviour: { autoHide: 3000, onClick: 'hide', onMouseover: 'pauseAutoHide', showDismissButton: true, stacking: 1 },
  animations: {
    enabled: true,
    show: { preset: 'slide', speed: 300, easing: 'ease' },
    hide: { preset: 'fade', speed: 300, easing: 'ease', offset: 50 },
    shift: { speed: 300, easing: 'ease' },
    overlap: 150
  }
};

export function init_app(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WrapperComponent,
    HeaderComponent,
    PaginationComponent,
    ForwardDeliveryViewComponent,
    LoadDetailsComponent,
    CreateShipmentComponent,
    APIShipmentsComponent,
    UserComponent,
    StoreComponent,
    ClientComponent,
    ReportComponent,
    RPReportComponent,
    ChangePasswordComponent,
    DashboardComponent,
    ListComponent,
    ManifestComponent,
    ManifestShipmentListComponent,
    RPManifestComponent,
    RPManifestShipmentListComponent,
    PickupManifestComponent,
    ReversePickupViewComponent,
    RpListComponent,
    StoreBarcodeListComponent,
    CSListComponent,
    APIListComponent,
    FCMNotificationComponent,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBarcode6Module,
    NgxPrintModule,
    NotifierModule.withConfig(customNotifierOptions),
    // AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // ServiceWorkerModule.register('firebase-messaging-sw.js', { enabled: true })
    // AngularFireDatabaseModule,
    // AngularFirestoreModule,
    // AngularFireStorageModule
  ],
  providers: [DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppConfigService],
      multi: true
    },
    AppConfigService,
    CookieService,
    DataService,
    MessagingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
