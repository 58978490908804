import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from '../../constants';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { LoginValidator } from './login.validator';
import { FormBuilder } from '@angular/forms';
import { Login } from '../../models/request/login';
import { AccountService } from '../../services/api/account.service';
import { MessagingService } from '../../services/messaging.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private login: Login;

  constructor(
    private router: Router,
    private ns: NotificationService,
    public validator: LoginValidator,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private messagingService: MessagingService) {
    this.login = new Login();
    this.validator.createForm(this.formBuilder);
  }

  ngOnInit(): void {
    this.validator.submitted = false;
  }

  doLogin() {
    sessionStorage.clear();
    if (!this.validator.isValid()) { return }
    this.login.username = this.validator.loginForm?.value.username;
    this.login.password = this.validator.loginForm?.value.password;
    let input = {
      "username": this.login.username,
      "password": this.login.password
    };
    this.accountService.doLogin(input).subscribe((response: any) => {
      this.validator.submitted = false;
      sessionStorage.setItem(CONSTANTS.COOKIE.ACCOUNT, JSON.stringify(response.account));
      sessionStorage.setItem(CONSTANTS.COOKIE.HEADER, response.token);
      sessionStorage.setItem(CONSTANTS.COOKIE.ISLOGGEDIN, "true");
      this.messagingService.requestPermission();
      if (["admin", "sub_admin"].indexOf(response.account.user_type) > -1) {
        this.router.navigate([`/${CONSTANTS.ROUTES.ROOT.ADMIN}`, CONSTANTS.ROUTES.DASHBOARD]);
      } else if (["client", "np", "np_sub_admin"].indexOf(response.account.user_type) > -1) {
        this.router.navigate([`/${CONSTANTS.ROUTES.ROOT.USER}`, CONSTANTS.ROUTES.DASHBOARD]);
      } else {
        this.ns.showAlert(CONSTANTS.ERROR, "Invalid Credentials")
      }
    });
  }
}
