import { ShipmentService } from '../../services/api/shipment.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { Router } from '@angular/router';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  sess_acc: any;
  allow: any = {
    booking: true,
    pe_assigned: true,
    picked_from_wh: true,
    arrived_at_hub: true
  };
  offset = 0;
  count = 10;
  totalItems = 0;
  shipments: any = [];

  seldate: any;
  count_map: any = {
    booking_created: 0 ,
    pickup_executive_assigned: 0 ,
    picked_from_warehouse: 0,
    arrived_at_hub: 0,
    arrived_at_destination_hub: 0,
    intransit: 0,
    out_for_delivery: 0,
    delivered: 0,
    rejected: 0,
    pending: 0,
    cancel: 0,
  };
  rp_count_map: any = {
    picked_from_customer: 0 ,
    delivered: 0,
  };
  fcmNotificationParams: any = { header: '', msg: '' };
  searchTxt: any;
  token: any;
  subscriber_id: any = 'dashboard';

  constructor(private dataService: DataService, private shipmentService: ShipmentService,
    private ns: NotificationService, private utilityService: UtilityService, private router: Router,
    private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    this.seldate = new Date().toISOString().substring(0, 10);
    this.calculate_allowed();
    this.loadDashboard('courier');
    this.loadDashboard('reverse_pickup');
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  calculate_allowed() {
    let uts: any = CONSTANTS.USER_TYPES;
    this.allow = {
      booking: [uts.ADMIN, uts.SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      pe_assigned: [uts.ADMIN, uts.SUB_ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      picked_from_wh: [uts.ADMIN, uts.SUB_ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      arrived_at_hub: [uts.ADMIN, uts.SUB_ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      picked_from_customer: [uts.ADMIN, uts.SUB_ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
      rp_delivered: [uts.ADMIN, uts.SUB_ADMIN, uts.NETWORK_PARTNER, uts.NETWORK_PARTNER_SUB_ADMIN, uts.CLIENT].indexOf(this.sess_acc.user_type) > -1,
    };
  }

  onRefresh() {
    this.loadDashboard('courier');
    this.loadDashboard('reverse_pickup');
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 10;
  }

  loadDashboard(stype: any) {
    let input = { 'shipment_type': stype };
    this.shipmentService.dashboard(input).subscribe((response: any) => {
      if (stype == 'courier') {
        let cm: any = response.shipment_count;
        cm.booking_created = cm.booking_created || 0;
        cm.pickup_executive_assigned = cm.pickup_executive_assigned || 0;
        cm.picked_from_warehouse = cm.picked_from_warehouse || 0;
        cm.arrived_at_hub = cm.arrived_at_hub || 0;
        cm.arrived_at_destination_hub = cm.arrived_at_destination_hub || 0;
        cm.intransit = cm.intransit || 0;
        cm.out_for_delivery = cm.out_for_delivery || 0;
        cm.delivered = cm.delivered || 0;
        cm.rejected = cm.rejected || 0;
        cm.pending = cm.pending || 0;
        cm.cancel = cm.cancel || 0;
        this.count_map = cm;
      } else {
        let cm: any = response.rp_shipment_count;
        cm.picked_from_customer = cm.picked_from_customer || 0;
        cm.rp_delivered = cm.rp_delivered || 0;
        this.rp_count_map = cm;
      }
    });
  }

  clearSearch() {
    this.searchTxt = ""
    this.shipments = [];
    this.totalItems = 0;
    this.offset = 0;
  }

  searchShipment() {
    let input = {
      "order_no": this.searchTxt,
      "dt": "courier_web_list"
    };
    this.shipmentService.search(input).subscribe((response: any) => {
      if (response.shipment != null) {
        this.shipments = [];
        this.shipments.push(response.shipment);
        this.totalItems = 1;
      } else {
        this.ns.showAlert(CONSTANTS.ERROR, "Waybill doesn't exist");
        this.shipments = [];
        this.totalItems = 0;
        this.offset = 0;
      }
      this.searchTxt = ""
    });
  }

  navigate(stype: any, page: any) {
    let tabs: any = (stype == 'forward') ? CONSTANTS.SHIPMENT_DISPLAY_NAMES : CONSTANTS.RP_SHIPMENT_DISPLAY_NAMES;
    let tab: any, tab_option: any;
    if (tabs[page]) {
      tab = page;
    } else if (tabs.booking[page]) {
      tab = 'booking';
      tab_option = page;
    }
    if (!tab) return;
    this.dataService.setSelectedTab({tab: tab, tab_option: tab_option});
    let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
    let view: any = (stype == 'forward') ? CONSTANTS.ROUTES.VIEW : CONSTANTS.ROUTES.RP_VIEW;
    this.router.navigate([root, view]);
  }

  downloadShipmentPOD(shipment: any) {
    let input = {
      "shipment_id": shipment.id
    };
    this.shipmentService.downloadPOD(input).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'pod.pdf';
          a.click();
        }
      });
    });
  }

  downloadPOD(shipment: any) {
    let input = {
      "shipment_id": shipment.id,
      "name": "pod",
    };
    this.shipmentService.downloadData(input).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'pod.zip';
          a.click();
          this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  downloadSign(shipment: any) {
    let input = {
      "shipment_id": shipment.id,
      "name": "sign",
    };
    this.shipmentService.downloadData(input).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'sign.zip';
          // start download
          a.click();
          this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  get_display_status(shipment: any) {
    if (shipment.shipment_type == CONSTANTS.SHIPMENT_FLOWS.FORWARD) {
      let names: any = CONSTANTS.SHIPMENT_DISPLAY_NAMES;
      return names.booking[shipment.shipment_status] || names[shipment.shipment_status];
    } else {
      let names: any = CONSTANTS.RP_SHIPMENT_DISPLAY_NAMES;
      return names[shipment.shipment_status];
    }
  }

  get_awb_no(shipment: any) {
    return shipment.details.bar_code;
  }

  get_client_name(shipment: any) {
    return shipment.created_for?.user?.full_name;
  }

  get_ref_name(shipment: any) {
    return shipment.details.order_no;
  }

  get_booking_place(shipment: any) {
    return (shipment.shipment_type == CONSTANTS.SHIPMENT_FLOWS.FORWARD) ? shipment.details?.pickup_city.toUpperCase() : shipment.details?.store;
  }

  get_delivery_place(shipment: any) {
    return (shipment.shipment_type == CONSTANTS.SHIPMENT_FLOWS.FORWARD) ? shipment.details?.delivery_city.toUpperCase() : shipment.created_for?.details?.locality?.toUpperCase();
  }

  get_delivery_dt(shipment: any) {
    return (shipment.shipment_type == CONSTANTS.SHIPMENT_FLOWS.FORWARD) ? shipment.details?.delivered?.dtt : shipment.details?.rp_delivered?.dtt;
  }

  can_show_sign(shipment: any) {
    return (shipment.shipment_type == CONSTANTS.SHIPMENT_FLOWS.FORWARD) ? (shipment.details?.delivered?.sign_fn != null) : (shipment.details?.rp_delivered?.sign_fn != null)
  }

  can_show_pod(shipment: any) {
    return (shipment.shipment_type == CONSTANTS.SHIPMENT_FLOWS.FORWARD) ? (shipment.details?.delivered?.pod_fn != null) : (shipment.details?.rp_delivered?.pod_fn != null)
  }

  can_show_shipment_pod(shipment: any) {
    return (shipment.shipment_type == CONSTANTS.SHIPMENT_FLOWS.FORWARD) && (shipment.shipment_status == CONSTANTS.SHIPMENT_TYPE.DELIVERED);
  }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }
}
