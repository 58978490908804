import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipmentService } from '../../services/api/shipment.service';
import { AccountService } from '../../services/api/account.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.scss']
})
export class ManifestComponent implements OnInit, OnDestroy {

  sess_acc: any;
  selDate: any;
  allow: any = {
    city_selection: true,
    np_selection: true
  };

  cities: any;
  selCity: any;

  manifest_nps: any = [];
  selNP: any;

  manifests: any = [];
  shipments: any = [];

  all_selected: any = false;
  any_selected: any = false;

  nps: any;
  assignNPParams: any = {
    selNPId: ""
  };
  fcmNotificationParams: any = { header: '', msg: '' };
  subscriber_id: any = 'manifest';

  constructor(private accountService: AccountService, private dataService: DataService,
    private shipmentService: ShipmentService, private ns: NotificationService,
    private utilityService: UtilityService, private jq: JqueryUtils, private router: Router) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.selDate = new Date().toISOString().substring(0, 10);
    this.calculate_allowed();
    this.onDateChanged();
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  calculate_allowed() {
    let uts: any = CONSTANTS.USER_TYPES;
    this.allow = {
      city_selection: [uts.ADMIN, uts.SUB_ADMIN].indexOf(this.sess_acc.user_type) > -1,
      np_selection: [uts.ADMIN, uts.SUB_ADMIN].indexOf(this.sess_acc.user_type) > -1,
    };
  }

  onDateChanged() {
    if (this.allow.city_selection) {
      this.loadDestinationCities();
    } else {
      this.loadManifests();
    }
  }

  onSelectedCity() {
    let city_tag: any = "";
    let city: any = this.getCityById(this.selCity);
    if (city) {
      city_tag = city.tag;
    }
    this.loadManifestNPs(this.selDate, city_tag, this.onManifestNpsRetrieved.bind(this));
  }

  onSelectedManifestNP() {
    this.loadManifests();
  }

  onManifestSelected(manifest: any, event: any) {
    manifest.selected = event.target.checked;
    let all_selected: any = true, any_selected: any = false;
    for (let idx = 0; idx < this.manifests.length; idx++) {
      all_selected = all_selected && this.manifests[idx].selected;
      any_selected = any_selected || this.manifests[idx].selected;
    }
    this.all_selected = all_selected;
    this.any_selected = any_selected;
  }

  onAllManifestSelected(event: any) {
    this.all_selected = event.target.checked;
    for (let idx = 0; idx < this.manifests.length; idx++) {
      this.manifests[idx].selected = this.all_selected;
    }
    this.any_selected = this.all_selected;
  }

  onManifestNpsRetrieved(response: any) {
    let nps: any = [{ account_id: "0", user: { full_name: 'All' } }];
    this.manifest_nps = [];
    response.network_partners.forEach((np: any) => {
      nps.push(np);
    });
    this.manifest_nps = nps;
    this.selNP = this.manifest_nps[0].account_id;
    this.loadManifests();
  }

  openManifestGeneratedModal() {
    this.jq.openModal('manifestGeneratedModal');
  }

  closeManifest(manifest: any) {
    let ip: any = {
      shipment_manifest_id: [manifest._id]
    };
    this.shipmentService.generateManifest(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Manifest Generate successfully");
      this.loadManifests();
    });
  }

  closeManifestGeneratedModal() {
    this.jq.closeModal('manifestGeneratedModal');
  }

  openAssignNPModal(manifest: any) {
    let city: any = this.getCityByTag(manifest.delivery_city);
    if (!city) {
      return;
    }
    this.assignNPParams = { manifest: manifest };
    this.loadNPs(city.tag, this.onNpsRetrieved.bind(this));
  }

  closeAssignNPModal() {
    this.assignNPParams = { };
    this.jq.closeModal('assignNPModal');
  }

  onSelNP() { }

  assignNP(params: any) {
    let ip: any = {
      manifest_id: params.manifest._id,
      np_id: params.np_id
    };
    this.shipmentService.assignManifestNP(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Network Assigned successfully");
      this.assignNPParams = { };
      this.jq.closeModal('assignNPModal');
    });
  }

  onNpsRetrieved(response: any) {
    this.nps = [];
    let nps: any = [];
    response.accounts.forEach((np: any) => {
      nps.push({
        _id: np.user._id,
        full_name: np.user.full_name
      });
    });
    this.nps = nps;
    if (this.assignNPParams.manifest.np) {
      this.assignNPParams.np_id = this.assignNPParams.manifest.np._id;
    } else {
      this.assignNPParams.np_id = this.nps[0]._id;
    }
    this.jq.openModal('assignNPModal');
  }

  viewShipments(manifest: any) {
    let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
    this.router.navigate([root, CONSTANTS.ROUTES.MANIFEST_SHIPMENT], { queryParams: {manifest_id: manifest._id}});
  }

  downloadManifest(manifest: any) {
    this.downloadSelectedManifest([manifest]);
  }

  downloadSelectedManifest(manifests: any) {
    let cs_manifests: any = '', mids = [];
    for (let idx = 0; idx < manifests.length; idx++) {
      mids.push(manifests[idx]._id);
    }
    cs_manifests = mids.join();
    if (cs_manifests == '') {
      this.ns.showAlert(CONSTANTS.ERROR, "Select atleast one manifest");
      return;
    }
    let ip: any = {
      manifest_id: cs_manifests
    };
    this.shipmentService.downloadManifest(ip).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'manifest.xlsx';
          // start download
          a.click();
          // this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  loadDestinationCities() {
    let ip: any = {
      date: this.selDate
    };
    this.shipmentService.getManifestCities(ip).subscribe((response: any) => {
      this.cities = [];
      let city_objs: any = [{_id:"-1", name: 'All', tag: 'all'}];
      response.cities.forEach((element: any, idx: any, arr: any) => {
        city_objs.push({_id: `${idx}`, name: element, tag: element});
      });
      this.cities = city_objs;
      this.selCity = this.cities[0]._id;
      this.onSelectedCity();
    });
  }

  loadNPs(city: any = null, cb: any = null) {
    let ip: any = {
      user_type: CONSTANTS.USER_TYPES.NETWORK_PARTNER,
      locality: city
    };
    this.accountService.getAccounts(ip).subscribe((response: any) => {
      if (cb) {
        cb(response);
      }
    });
  }

  loadManifestNPs(date: any, city: any = null, cb: any = null) {
    let ip: any = {
      date: date,
      city: city
    };
    this.shipmentService.getManifestNps(ip).subscribe((response: any) => {
      if (cb) {
        cb(response);
      }
    });
  }

  loadManifests(retain_selection: any = false) {
    let city_tag: any = '';
    let np_id: any = '';
    if (this.allow.city_selection) {
      let city: any = this.getCityById(this.selCity);
      if (city) {
        city_tag = city.tag;
      }
    }
    if (this.allow.np_selection) {
      let np: any = this.getManifestNPById(this.selNP);
      if (np) {
        np_id = np.user.user_id;
      }
    }
    let ip: any = {
      date: this.selDate,
      np_id: np_id,
      delivery_city: city_tag
    };
    this.shipmentService.getManifests(ip).subscribe((response: any) => {
      let ids: any = retain_selection ? this.getSelectedManifests() : [];
      this.all_selected = (ids.length == 0) ? false : this.all_selected;
      this.manifests = [];
      this.manifests = response.manifests.map((manifest: any) => {
        manifest.selected = (ids.indexOf(manifest._id) > -1);
        return manifest;
      });
    });
  }

  refreshManifests() {
    this.loadManifests();
  }

  mergeManifest() {
    let ids: any = this.getSelectedManifests();
    if (ids.length <= 1) {
      this.ns.showAlert(CONSTANTS.ERROR, "Please select atleast 2 manifests to merge.");
      return;
    }
    let ip: any = {
      shipment_manifest_id: ids
    };
    this.shipmentService.mergeManifests(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Manifests merged successfully.");
      this.loadManifests();
    });
  }

  can_close(manifest: any) {
    let close: any = (manifest.state == 'open') && (manifest.details.status == 'completed');
    if (close) {
      let uts: any = CONSTANTS.USER_TYPES;
      close = [uts.ADMIN, uts.NETWORK_PARTNER].indexOf(this.sess_acc.user_type) > -1;
    }
    return close;
  }

  getSelectedManifests() {
    let ids: any = [];
    for (let idx = 0; idx < this.manifests.length; idx++) {
      if (this.manifests[idx].selected) {
        ids.push(this.manifests[idx]._id);
      }
    }
    return ids;
  }

  getCityById(city_id: any) {
    let city: any = null;
    for (let idx = 0; idx < this.cities.length; idx++) {
      if ((this.cities[idx]._id !== "-1") && (this.cities[idx]._id === city_id)) {
        city = this.cities[idx];
        break;
      }
    }
    return city;
  }

  getCityByTag(tag: any) {
    let city: any = null;
    for (let idx = 0; idx < this.cities.length; idx++) {
      if ((this.cities[idx]._id !== "-1") && (this.cities[idx].tag === tag.toLowerCase())) {
        city = this.cities[idx];
        break;
      }
    }
    return city;
  }

  getManifestNPById(np_id: any) {
    let np: any = null;
    for (let idx = 0; idx < this.manifest_nps.length; idx++) {
      if ((this.manifest_nps[idx].account_id !== "0") && (this.manifest_nps[idx].account_id === np_id)) {
        np = this.manifest_nps[idx];
        break;
      }
    }
    return np;
  }

  getNPById(np_id: any) {
    let np: any = null;
    for (let idx = 0; idx < this.nps.length; idx++) {
      if ((this.nps[idx]._id !== "") && (this.nps[idx]._id === np_id)) {
        np = this.nps[idx];
        break;
      }
    }
    return np;
  }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }

  calculateSelectedManifests() {
    let manis: any = [];
    for (let idx = 0; idx < this.manifests.length; idx++) {
      if (!!this.manifests[idx].selected) {
        manis.push(this.manifests[idx]);
      }
    }
    return manis;
  }
}
