import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class DistrictService extends ApiService {

  public getDistricts(data: any) {
    return this.getQueryParams(CONSTANTS.API.DISTRICT.GET_DISTRICTS, data);
  }
  public addDistrict(data: any) {
    return this.post(CONSTANTS.API.DISTRICT.ADD_DISTRICT, data);
  }
  public updateDistrictTag(data: any) {
    return this.put(CONSTANTS.API.DISTRICT.UPDATE_DISTRICT_TAG, data);
  }
}
