import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";

import { FCMService } from "./api/fcm.service";
import * as EventEmitter from "events";

@Injectable()
export class MessagingService {

    onMessageReceived: EventEmitter = new EventEmitter();

    constructor(private messaging: AngularFireMessaging, private fcmService: FCMService) {}

    getFireMessaging() { return this.messaging; }

    requestPermission() {
        this.messaging.requestPermission.subscribe(
          () => {
            this.messaging.getToken.subscribe((token: any) => {
              let ip: any = { fcm_token: token };
              this.fcmService.saveToken(ip).subscribe((response: any) => {
              });
            });
            console.log('Permission granted!');
          },
          (error: any) => {
            console.log('Permission denied:', error);
          }
        );
    }
}
