<div class="container">
  <div class="row sub-header-buffer">
    <table class="table table-striped ecom-table">
      <thead class="table-header">
        <tr>
          <th class="col-2">
            <div>API DETAILS</div>
          </th>
          <th class="col-8">
            <div>VALUE</div>
          </th>
          <th class="col-2">ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="col-2">
            <div>API Key</div>
          </td>
          <td class="col-8">
            <div>{{api_details.api_key}}</div>
          </td>
          <td class="col-2">
            <i class="fas fa-rotate-right text-success mx-1" (click)="regenerate('api_key')"></i>
          </td>
        </tr>
        <tr>
          <td class="col-2">
            <div>Refresh Token</div>
          </td>
          <td class="col-8">
            <div>{{api_details.refresh_token}}</div>
          </td>
          <td class="col-2">
            <i class="fas fa-rotate-right text-success mx-1" (click)="regenerate('refresh_token')"></i>
          </td>
        </tr>
        <tr>
          <td class="col-2">
            <div>Access Token</div>
          </td>
          <td class="col-8">
            <div class="text-break">
              {{api_details.access_token}}
            </div>
          </td>
          <td class="col-2">
            <i class="fas fa-check text-success mx-1" *ngIf="api_details.valid"></i>
            <i class="fas fa-circle-xmark text-danger mx-1" *ngIf="!api_details.valid"></i>
            <i class="fas fa-rotate-right text-success mx-1" (click)="regenerate('access_token')"></i>
            <i class="fas fa-magnifying-glass text-secondary mx-1" (click)="isAccessTokenValid()"></i>
          </td>
        </tr>
        <tr>
          <td class="col-2">
            <div>Timeout</div>
          </td>
          <td class="col-8">
            <div *ngIf="!api_details.time_edit_mode">
              {{api_details.expiry_period}}
            </div>
            <div *ngIf="api_details.time_edit_mode">
              <div class="input-group mb-3">
                <input type="text" class="form-control timeout-no-border" placeholder="Timeout" aria-label="Timeout" autocomplete="off"
                aria-describedby="timeout" [(ngModel)]="api_details.expiry_period">
                <div class="input-group-append adjust-timeout-ip-grp">
                  <span class="input-group-text" id="timeout">In Hours</span>
                </div>
              </div>
            </div>
          </td>
          <td class="col-2">
            <i class="fas fa-check text-success mx-1" (click)="saveTimeout()" *ngIf="api_details.time_edit_mode"></i>
            <i class="fas fa-circle-xmark text-danger mx-1" *ngIf="api_details.time_edit_mode" (click)="cancelTimeout()"></i>
            <i class="fas fa-pen-to-square mx-1" *ngIf="!api_details.time_edit_mode" (click)="showEditMode()"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
