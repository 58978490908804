import {Injectable, EventEmitter } from "@angular/core";    

@Injectable()
export class DataService {

    selected_tab: any;
    toggleMenu: any = {};
    shipmentListPage: any = {};
    shipmentListTab: any = {};
    rpShipmentListPage: any = {};
    rpShipmentListTab: any = {};
    rpAssign: any = {};
    rpShipmentSelected: any = {};
    loggedOut: any = {};
    apiError: any = {};
    fcmNotificationReceived: any = {};

    onToggleMenuClicked(subscriber: any) {
        this.toggleMenu[subscriber] = this.toggleMenu[subscriber] || new EventEmitter();
        return this.toggleMenu[subscriber];
    }
    onShipmentListPageSelected(subscriber: any): EventEmitter<any> {
        this.shipmentListPage[subscriber] = this.shipmentListPage[subscriber] || new EventEmitter();
        return this.shipmentListPage[subscriber];
    }
    onShipmentListTabSelected(subscriber: any): EventEmitter<any> {
        this.shipmentListTab[subscriber] = this.shipmentListTab[subscriber] || new EventEmitter();
        return this.shipmentListTab[subscriber];
    }
    onRPShipmentListPageSelected(subscriber: any): EventEmitter<any> {
        this.rpShipmentListPage[subscriber] = this.rpShipmentListPage[subscriber] || new EventEmitter();
        return this.rpShipmentListPage[subscriber];
    }
    onRPShipmentListTabSelected(subscriber: any): EventEmitter<any> {
        this.rpShipmentListTab[subscriber] = this.rpShipmentListTab[subscriber] || new EventEmitter();
        return this.rpShipmentListTab[subscriber];
    }
    onRPAssign(subscriber: any): EventEmitter<any> {
        this.rpAssign[subscriber] = this.rpAssign[subscriber] || new EventEmitter();
        return this.rpAssign[subscriber];
    }
    onRPShipmentSelected(subscriber: any): EventEmitter<any> {
        this.rpShipmentSelected[subscriber] = this.rpShipmentSelected[subscriber] || new EventEmitter();
        return this.rpShipmentSelected[subscriber];
    }
    onLoggedOut(subscriber: any): EventEmitter<any> {
        this.loggedOut[subscriber] = this.loggedOut[subscriber] || new EventEmitter();
        return this.loggedOut[subscriber];
    }
    onAPIError(subscriber: any): EventEmitter<any> {
        this.apiError[subscriber] = this.apiError[subscriber] || new EventEmitter();
        return this.apiError[subscriber];
    }
    onFcmNotificationReceived(subscriber: any): EventEmitter<any> {
        this.fcmNotificationReceived[subscriber] = this.fcmNotificationReceived[subscriber] || new EventEmitter();
        return this.fcmNotificationReceived[subscriber];
    }

    offToggleMenuClicked(subscriber: any) {
        let emitter = this.toggleMenu[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.toggleMenu[subscriber]
        }
    }
    offShipmentListPageSelected(subscriber: any) {
        let emitter = this.shipmentListPage[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.shipmentListPage[subscriber]
        }
    }
    offShipmentListTabSelected(subscriber: any) {
        let emitter = this.shipmentListTab[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.shipmentListTab[subscriber]
        }
    }
    offRPShipmentListPageSelected(subscriber: any) {
        let emitter = this.rpShipmentListPage[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.rpShipmentListPage[subscriber]
        }
    }
    offRPShipmentListTabSelected(subscriber: any) {
        let emitter = this.rpShipmentListTab[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.rpShipmentListTab[subscriber]
        }
    }
    offRPAssign(subscriber: any) {
        let emitter = this.rpAssign[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.rpAssign[subscriber]
        }
    }
    offRPShipmentSelected(subscriber: any) {
        let emitter = this.rpShipmentSelected[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.rpShipmentSelected[subscriber]
        }
    }
    offLoggedOut(subscriber: any) {
        let emitter = this.loggedOut[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.loggedOut[subscriber]
        }
    }
    offAPIError(subscriber: any) {
        let emitter = this.apiError[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.apiError[subscriber]
        }
    }
    offFcmNotificationReceived(subscriber: any) {
        let emitter = this.fcmNotificationReceived[subscriber];
        if (emitter) {
            emitter.unsubscribe();
            delete this.fcmNotificationReceived[subscriber]
        }
    }
    toggleMenuClicked(data: any) {
        for (let subscriber in this.toggleMenu) {
            this.toggleMenu[subscriber].emit(data);
        }
    }
    shipmentListPageSelected(data: any) {
        for (let subscriber in this.shipmentListPage) {
            this.shipmentListPage[subscriber].emit(data);
        }
    }
    shipmentListTabSelected(data: any) {
        for (let subscriber in this.shipmentListTab) {
            this.shipmentListTab[subscriber].emit(data);
        }
    }
    rpShipmentListPageSelected(data: any) {
        for (let subscriber in this.rpShipmentListPage) {
            this.rpShipmentListPage[subscriber].emit(data);
        }
    }
    rpShipmentListTabSelected(data: any) {
        for (let subscriber in this.rpShipmentListTab) {
            this.rpShipmentListTab[subscriber].emit(data);
        }
    }
    rpAssignShipments(data: any) {
        for (let subscriber in this.rpAssign) {
            this.rpAssign[subscriber].emit(data);
        }
    }
    invokeRPShipmentSelected(data: any) {
        for (let subscriber in this.rpShipmentSelected) {
            this.rpShipmentSelected[subscriber].emit(data);
        }
    }
    logOut(data: any) {
        for (let subscriber in this.loggedOut) {
            this.loggedOut[subscriber].emit(data);
        }
    }
    apiErrorTrigerred(data: any) {
        for (let subscriber in this.apiError) {
            this.apiError[subscriber].emit(data);
        }
    }
    fcmNotified(data: any) {
        for (let subscriber in this.fcmNotificationReceived) {
            this.fcmNotificationReceived[subscriber].emit(data);
        }
    }

    setSelectedTab(selected_tab: any) { this.selected_tab = selected_tab; }

    getSelectedTab() { return this.selected_tab; }
}