<div class="container px-0">
    <div class="row sub-header-buffer">
        <div class="col-md-8">
            <div class="input-group">
                <input type="text" class="form-control h-auto" [(ngModel)]="searchTxt" placeholder="Search by AWB No" />
                <span class="input-group-btn search-border-style">
                    <button class="btn btn-default">
                        <i class="fa fa-xmark text-danger" (click)="clearSearch()"></i>
                    </button>
                </span>
            </div>
        </div>
        <div class="col-md-2 d-flex align-items-center">
            <button type="button" class="btn btn-ecom px-5" (click)="searchShipment()">
                Search
            </button>
        </div>
        <div class="col-md-2 d-flex align-items-center">
            <button type="button" class="btn btn-ecom px-5" (click)="refreshShipments()">
                Refresh
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs justify-content-end sub-header-buffer">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" [ngClass]="setActiveTab('booking')" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="javascript:void(0)">{{ tab_txt.booking }}</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="onTabOptionSelected('booking_created', 'booking')" [ngClass]="setActiveOption('booking_created')" *ngIf="allow.booking.booking_created" href="javascript:void(0)">Booking</a>
            <a class="dropdown-item" (click)="onTabOptionSelected('pickup_executive_assigned', 'booking')" [ngClass]="setActiveOption('pickup_executive_assigned')" *ngIf="allow.booking.pickup_executive_assigned" href="javascript:void(0)">Executive Assigned</a>
            <a class="dropdown-item" (click)="onTabOptionSelected('picked_from_warehouse', 'booking')" [ngClass]="setActiveOption('picked_from_warehouse')" *ngIf="allow.booking.picked_from_warehouse" href="javascript:void(0)">Warehouse Pickup</a>
            <a class="dropdown-item" (click)="onTabOptionSelected('arrived_at_hub', 'booking')" [ngClass]="setActiveOption('arrived_at_hub')" *ngIf="allow.booking.arrived_at_hub" href="javascript:void(0)">At Hub</a>
            <a class="dropdown-item" (click)="onTabOptionSelected('arrived_at_destination_hub', 'booking')" [ngClass]="setActiveOption('arrived_at_destination_hub')" *ngIf="allow.booking.arrived_at_destination_hub" href="javascript:void(0)">At Destination Hub</a>
          </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onTabSelected('intransit')" [ngClass]="setActiveTab('intransit')" role="tab" href="javascript:void(0)">{{ tab_txt.intransit }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onTabSelected('out_for_delivery')" role="tab" [ngClass]="setActiveTab('out_for_delivery')" href="javascript:void(0)">{{ tab_txt.out_for_delivery }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onTabSelected('delivered')" role="tab" [ngClass]="setActiveTab('delivered')" href="javascript:void(0)">{{ tab_txt.delivered }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onTabSelected('pending')" role="tab" [ngClass]="setActiveTab('pending')" href="javascript:void(0)">{{ tab_txt.pending }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onTabSelected('rejected')" role="tab" [ngClass]="setActiveTab('rejected')" href="javascript:void(0)">{{ tab_txt.rejected }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onTabSelected('cancel')" role="tab" [ngClass]="setActiveTab('cancel')" href="javascript:void(0)">{{ tab_txt.cancel }}</a>
        </li>
    </ul>
    <div class="tab-content" id="nav-tabContent">
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('booking')" id="nav-booking" aria-labelledby="nav-booking-tab"><app-fd-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'booking'" [tab]="selected_tab_option"></app-fd-list></div>
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('arrived_at_np_hub')" id="nav-arrived-np-hub" aria-labelledby="nav-arrived-np-hub-tab"><app-fd-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'arrived_at_np_hub'" [tab]="selected_tab_option"></app-fd-list></div>
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('intransit')" id="nav-intransit" aria-labelledby="nav-intransit-tab"><app-fd-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'intransit'" [tab]="selected_tab_option"></app-fd-list></div>
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('out_for_delivery')" id="nav-out-for-delivery" aria-labelledby="nav-out-for-delivery-tab"><app-fd-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'out_for_delivery'" [tab]="selected_tab_option"></app-fd-list></div>
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('delivered')" id="nav-delivered" aria-labelledby="nav-delivered-tab"><app-fd-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'delivered'" [tab]="selected_tab_option"></app-fd-list></div>
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('pending')" id="nav-pending" aria-labelledby="nav-pending-tab"><app-fd-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'pending'" [tab]="selected_tab_option"></app-fd-list></div>
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('rejected')" id="nav-rejected" aria-labelledby="nav-rejected-tab"><app-fd-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'rejected'" [tab]="selected_tab_option"></app-fd-list></div>
        <div role="tabpanel" class="tab-pane" [ngClass]="setActiveTab('cancel')" id="nav-cancel" aria-labelledby="nav-cancel-tab"><app-fd-list [shipments]="shipments" [totalItems]="totalItems" [shipmentType]="'cancel'" [tab]="selected_tab_option"></app-fd-list></div>
    </div>
</div>
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
