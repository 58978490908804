import { ShipmentService } from '../../services/api/shipment.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-loaddetails',
  templateUrl: './loaddetails.component.html',
  styleUrls: ['./loaddetails.component.scss']
})
export class LoadDetailsComponent implements OnInit {

  id: any;
  waybillNo: any;
  invoices: any = [];

  constructor(private shipmentService: ShipmentService, private ns: NotificationService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'],
      sessionStorage.setItem(CONSTANTS.COOKIE.HEADER, params['token'])
      this.waybillNo = params['waybill']
    });
    this.getInvoices();
  }

  getInvoices() {
    let input = {
      "id": this.id
    };
    this.shipmentService.getInvoices(input).subscribe((data: any) => {
      this.invoices = [];
      if (data.invoices != null && data.invoices.length > 0) {
          this.invoices = data.invoices;
      } else {
          this.ns.showAlert(CONSTANTS.SUCCESS, "No Items found");
      }
    });
  }
}
