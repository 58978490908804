<div class="container">
    <div class="row header-buffer">
        <h3>INVOICE DETAILS</h3>
    </div>
    <div class="row sub-header-buffer">
        <label>WayBill No: &nbsp;</label>
        <span>{{waybillNo}}</span>
    </div>
    <div class="row sub-header-buffer">
        <table class="table ecom-table">
            <thead class="table-header">
                <tr>
                    <th>
                        <div>Invoice No</div>
                        <div>Invoice Date</div>
                    </th>
                    <th>
                        <div>Booking Date</div>
                        <div>Booking Place</div>
                    </th>
                    <th>Description</th>
                    <th>STORE CODE</th>
                    <th>
                        <div>Drop Location</div>
                        <div>Pincode</div>
                    </th>
                    <th>
                        <div>Total Quantity</div>
                        <div>Total Weight</div>
                    </th>
                    <th>Box</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let invoice of invoices">
                    <td>
                        <div>{{invoice.invoice_no}}</div>
                        <div>{{invoice.invoice_date}}</div>
                    </td>
                    <td>
                        <div>{{invoice.booking_date}}</div>
                        <div>{{invoice.booking_place}}</div>

                    </td>
                    <td>{{invoice.description}}</td>
                    <td>{{invoice.store}}</td>
                    <td>
                        <div>{{invoice.delivery_place}}</div>
                        <div>{{invoice.pincode}}</div>
                    </td>
                    <td>
                        <div>{{invoice.total_qty}}</div>
                        <div>{{invoice.total_weight}}</div>
                    </td>
                    <td>
                        {{invoice.box}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>