<div class="container">
    <div class="row sub-header-buffer">
        <div class="col-md-3">
            <label>From</label>
            <div class="form-group">
                <input type="date" name="shipment_creation_date" class="form-control" [(ngModel)]="selection.date" (change)="onDateChanged()">
            </div>
        </div>
        <div class="col-md-3">
            <label>Intracity/Intercity</label>
            <div class="form-group">
                <select class="form-select select-dd" [(ngModel)]="selUploadTypeId" (change)="onSelUploadType()">
                    <option *ngFor="let utype of utypes" [value]="utype.uid"> {{ utype.full_name }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <label>PICKUP STATE</label>
            <div class="input-group">
                <select class="select-dd delivery-dd" [(ngModel)]="selPickupStateId" (change)="onSelState('pickup')">
                    <option *ngFor="let state of states" [value]="state._id"> {{ state.full_name }}</option>
                </select>
                <span>
                    <button class="btn btn-default delivery-cls-btn">
                        <i class="fa fa-xmark text-danger" (click)="clearSearch('pickup', 'state')"></i>
                    </button>
                </span>
            </div>
        </div>
        <div class="col-md-3">
            <label>PICKUP DISTRICT</label>
            <div class="input-group">
                <select class="select-dd delivery-dd" [(ngModel)]="selPickupDistrictId" (change)="onSelDistrict('pickup')">
                    <option *ngFor="let district of pickup_districts" [value]="district._id"> {{ district.full_name }}</option>
                </select>
                <span>
                    <button class="btn btn-default delivery-cls-btn">
                        <i class="fa fa-xmark text-danger" (click)="clearSearch('pickup', 'district')"></i>
                    </button>
                </span>
            </div>
        </div>
        <div class="col-md-3">
            <label>ASSIGN STATE</label>
            <div class="form-group">
                <select class="form-select select-dd" [(ngModel)]="selAssignStateId" (change)="onSelAssignState()">
                    <option *ngFor="let assign_state of assign_states" [value]="assign_state._id"> {{ assign_state.full_name }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3" *ngIf="show_client_selection">
            <label>CLIENT</label>
            <div class="form-group">
                <select class="form-select select-dd" [(ngModel)]="selClientId" (change)="onSelClient()">
                    <option *ngFor="let client of clients" [value]="client.user.user_id"> {{ client.user.full_name }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-5">
            <label>&nbsp;</label>
            <div>
                <div class="btn-group">
                    <button class="btn btn-ecom btn-rad mx-1" (click)="refreshList()">REFRESH</button>
                    <button class="btn btn-ecom btn-rad mx-1" (click)="openSelectionAssignFEModal('all')" *ngIf="allow_assign">ASSIGN ALL</button>
                    <button class="btn btn-ecom btn-rad mx-1" (click)="openSelectionAssignFEModal('selected')" *ngIf="allow_assign">ASSIGN SELECTED</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row sub-header-buffer">
        <div id="completedT" class="col-md-12">
            <table class="table ecom-table">
                <thead class="table-header">
                    <tr>
                        <th class="text-center align-middle p-0">
                            <input type="checkbox" [checked]="all_selected" (change)="onAllSUDsSelected($event)"
                              aria-label="Checkbox for selecting all suds">
                        </th>
                        <th>
                            <div>UPLOADED TIME</div>
                            <div>FILE NAME</div>
                        </th>
                        <th>
                            <div>ORIGIN</div>
                            <div>DESTINATION</div>
                        </th>
                        <th>
                            <div>UPLOADED BY</div>
                            <div>CLIENT NAME</div>
                        </th>
                        <th>
                            <div>PARTNER NAME</div>
                            <div>EXECUTIVE NAME</div>
                        </th>
                        <th>
                            <div>Intracity/Intercity</div>
                        </th>
                        <th>
                            <div>PROCESSED</div>
                        </th>
                        <th>
                            ACTIONS
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sud of suds">
                        <td class="text-center align-middle p-0">
                            <input type="checkbox" [checked]="sud.selected" (change)="onSUDSelected(sud, $event)"
                              aria-label="Checkbox for selecting sud">
                        </td>
                        <td>
                            <div>{{sud.created_date}}</div>
                            <div>{{sud.file_name}}</div>
                        </td>
                        <td>
                            <div>{{sud.pickup_city.toUpperCase()}}</div>
                            <div>{{sud.delivery_city.toUpperCase()}}</div>
                        </td>
                        <td>
                            <div>{{sud.uploaded_by?.user?.full_name}}</div>
                            <div>{{sud.uploaded_for?.user?.full_name}}</div>
                        </td>
                        <td>
                            <div>{{sud.details.np?.user?.full_name}}</div>
                            <div>{{sud.details.fe?.user?.full_name}}</div>
                        </td>
                        <td>
                            <div *ngIf="sud.pickup_city.toUpperCase() == sud.delivery_city.toUpperCase()"><h6>Intracity</h6></div>
                            <div *ngIf="sud.pickup_city.toUpperCase() != sud.delivery_city.toUpperCase()"><h6 class="text-danger">**Intercity**</h6></div>
                        </td>
                        <td>
                            <div>{{sud.details.processed}}/{{sud.details.total}}</div>
                        </td>
                        <td class="text-center">
                            <i class="fas fa-pencil text-primary mx-1" *ngIf="sud.details.processed < sud.details.total" (click)="updateShipments(sud)"></i>
                            <i class="fas fa-eye text-primary mx-1" *ngIf="sud.details.processed < sud.details.total" (click)="viewShipments(sud)"></i>
                            <i class="fas fa-user-plus" *ngIf="sud.show_assign" [ngClass]="assign_class(sud)" (click)="openAssignFEModal(sud)"></i>
                            <i class="fas fa-barcode pl-2" (click)="showBarcodes(sud)"></i>
                            <i class="fas fa-print pl-2" (click)="printBarcodes(sud)"></i>
                            <i class="fas fa-download text-primary mx-1" (click)="downloadXls(sud)">
                            </i>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="15" class="center-align">
                            <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
                                <div class="col-md-12 pagenation">
                                    <app-pagination [totalItems]="totalItems"
                                        (onPrevSelected)="onPageSelected($event)" (onNextSelected)="onPageSelected($event)"
                                        (onFirstSelected)="onPageSelected($event)" (onLastSelected)="onPageSelected($event)"
                                        (onPageSelected)="onPageSelected($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="assignFEModal" tabindex="-1" role="dialog"
  aria-labelledby="assignFELabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>Assign Executive</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeAssignFEModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
            <div class="col-md-12">
              <label>Network Partner</label>
              <div class="form-group">
                  <select class="form-select select-dd" [(ngModel)]="assignFEParams.selNpId" (change)="onSelNp(assignFEParams)">
                      <option *ngFor="let np of assignFEParams.nps" [value]="np.user.user_id"> {{ np.user.full_name }}</option>
                  </select>
              </div>
            </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label>Pickup Executives</label>
            <div class="form-group">
                <select class="form-select select-dd" [(ngModel)]="assignFEParams.selExecId" (change)="onSelExecutive(assignFEParams)">
                    <option *ngFor="let fe of assignFEParams.fes" [value]="fe.account_id"> {{ fe.user.full_name }}</option>
                </select>
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="assignExecutive(assignFEParams)">
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="editShipmentModal" tabindex="-1" role="dialog"
  aria-labelledby="editShipmentLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>Edit Shipment Details</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeEditShipmentModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
            <div class="col-md-12">
              <label>State</label>
              <div class="form-group">
                  <select class="form-select select-dd" [(ngModel)]="editShipmentParams.selStateId" (change)="onSelState('edit_shipment')">
                      <option *ngFor="let state of editShipmentParams.states" [value]="state._id"> {{ state.full_name }}</option>
                  </select>
              </div>
            </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label>District</label>
            <div class="form-group">
                <select class="form-select select-dd" [(ngModel)]="editShipmentParams.selDistrictId">
                    <option *ngFor="let district of editShipmentParams.districts" [value]="district._id"> {{ district.full_name }}</option>
                </select>
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="editShipment(editShipmentParams)">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Original -->
<div class="modal fade mt-0" id="barcodeModal" tabindex="-1" aria-labelledby="barcodeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered barcode-dialog">
        <div class="modal-content rounded-0">
            <div class="modal-body" id="print-section">
                <div class="row">
                    <div class="col border border-dark col-margin" *ngFor="let barcode of barcodes">
                        <div class="hstack align-barcode">
                            <label for="barcode" class="top_text barcode-label">SAAP Logistics</label>
                            <label for="barcode" class="top_text barcode-label ms-auto">{{ barcode.obj.store }}</label>
                        </div>
                        <ngx-barcode6 [bc-format]="'CODE128'" [bc-value]="barcode.barcode" [bc-width]="2" [bc-height]="75" 
                        [bc-text-align]="'center'" [bc-font-size]="14" [bc-display-value]="false"></ngx-barcode6>
                        <div class="align-barcode d-flex flex-column">
                            <label class="barcode-label">AWB : {{ barcode.barcode }}</label>
                            <label class="barcode-label">Ref No : {{ barcode.obj.order_no }}</label>
                            <label class="barcode-label">Box/Weight : {{ barcode.obj.total_boxes }}/{{ barcode.obj.total_weight }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeBarcodeModal()">Close</button>
                <!-- <button type="button" class="btn btn-primary" [useExistingCss]="true" printSectionId="print-section" ngxPrint (click)="printBarcodes()">Print</button> -->
            </div>
        </div>
    </div>
</div>
  
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
