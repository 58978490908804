import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class BarcodeService extends ApiService {

  public createBarcodes(data: any) {
    return this.post(CONSTANTS.API.BARCODE.GENERATE_STORE_BARCODE, data);
  }
  public getStoreBarcodeList(data: any) {
    return this.getQueryParams(CONSTANTS.API.BARCODE.STORE_BARCODE_LIST, data);
  }
}
