import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentService } from '../../services/api/shipment.service';
import { NotificationService } from '../../services/notification.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { CONSTANTS } from '../../constants';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-rp-manifest-shipment-list',
  templateUrl: './rp-manifest-shipment-list.component.html',
  styleUrls: ['./rp-manifest-shipment-list.component.scss']
})

export class RPManifestShipmentListComponent implements OnInit {

  sess_acc: any;
  token: any;
  offset: any;
  manifest_id: any;

  manifest: any = {};
  shipments: any = [];
  totalItems: any = 0;
  tableParams: any = {};
  viewBarcodeParams: any = {
    barcodes: []
  };
  subscriber_id: any = 'rp-ml';

  constructor(private shipmentService: ShipmentService, private dataService: DataService, private ns: NotificationService,
    private utilityService: UtilityService, public route: ActivatedRoute, private router: Router,
    private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    this.route.queryParams.subscribe(params => {
      this.manifest_id = params['manifest_id'];
    });
    if (!this.manifest_id) {
      let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
      this.router.navigate([root, CONSTANTS.ROUTES.RP_MANIFEST]);
    } else {
      this.loadShipments();
    }
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  loadShipments() {
    let ip: any = {
      manifest_id: [this.manifest_id],
      index: this.offset,
      count: 20,
    };
    this.shipmentService.getRPManifestShipments(ip).subscribe((response: any) => {
      this.shipments = response.shipments;
      this.totalItems = response.count;
      this.manifest = response.manifest;
      this.tableParams = {
        show_actions: true
      };
    });
  }

  showBarcodes(shipment: any) {
    let barcodes: any = [];
    for (let iidx = 0; iidx < shipment.details.total_box; iidx++) {
      barcodes.push({
        index: iidx,
        barcode: shipment.details.bar_code,
        box_no: (iidx + 1),
        obj: shipment,
        label: `Slide ${iidx}`
      });
    }
    this.viewBarcodeParams = {
      barcodes: barcodes
    };
    this.jq.openModal('rpManifestSLBarcodeModal');
  }

  closeBarcodeModal() {
    this.viewBarcodeParams = { barcodes: [] };
    this.jq.closeModal('rpManifestSLBarcodeModal');
  }

  printBarcodes(params: any) {
    this.viewBarcodeParams = { barcodes: [] };
    this.jq.closeModal('rpManifestSLBarcodeModal');
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.loadShipments();
  }
}
