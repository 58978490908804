import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class LoginValidator {

    public submitted: boolean = false;
    public loginForm!: FormGroup;
    public formBuilder!: FormBuilder;

    createForm(formBuilder: FormBuilder) {
        this.loginForm = formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    isValid() {
        this.submitted = true;
        return this.loginForm?.invalid ? false : true;
    }

    get f() { return this.loginForm?.controls; }

    get username() { return this.loginForm.get('username'); }
    get password() { return this.loginForm.get('password'); }
}