<div class="container-fluid banner-bg">
    <div class="row">
        <div class="col-md-8 align-self-center">
        </div>
        <div class="col-md-3 vertical-center">
            <div class="card card-body">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <img src="./assets/images/logosaap.png" class="logo-sizing">
                        <p class="f-16">Please Login To Continue</p>
                    </div>
                </div>
                <form [formGroup]="validator.loginForm" novalidate autocomplete="off">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Username</label>
                                <input type="text" class="form-control" formControlName="username" [ngClass]="{ 'is-invalid': validator.submitted && validator.username?.invalid }">
                                <div *ngIf="validator.submitted && validator.username?.invalid" class="invalid-feedback">
                                    <div *ngIf="validator.loginForm?.getError('required', 'username')">username is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Password</label>
                                <input type="password" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': validator.submitted && validator.password?.errors }">
                                <div *ngIf="validator.submitted && validator.password?.errors" class="invalid-feedback">
                                    <div *ngIf="validator.loginForm?.getError('required', 'password')">password is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-md-12">
                            <div class="form-group">
                                <button class="btn btn-2 btn-2h btn-block" (click)="doLogin()">LOGIN</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
