<div class="container">
    <div class="row header-buffer">
        <div class="col-md-12">
            <h4>CHANGE PASSWORD</h4>
        </div>
    </div>


    <div class="row">
        <div class="col-md-4">
            <label>OLD PASSWORD</label>
            <div class="form-group">
                <input type="password" class="form-control" autocomplete="off" [(ngModel)]="oldpass" />

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>NEW PASSWORD</label>
            <div class="form-group">
                <input type="password" class="form-control" autocomplete="off" [(ngModel)]="newpass" />

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>CONFIRM PASSWORD</label>
            <div class="form-group">
                <input type="password" class="form-control" autocomplete="off" [(ngModel)]="confirmpass" />

            </div>
        </div>
    </div>
    <div class="row sub-header-buffer">
        <div class="col-md-4 text-center">

            <button class="btn btn-ecom px-5" (click)="reset()">
              SUBMIT
            </button>
        </div>
    </div>
</div>
