export class ListUsers {
  constructor() { }
  _id: String | null = null;
  client_secret: String | null = null;
  two_fa: any = [];
}

export class AddUser {
  constructor() { }
  _id: String | null = null;
  email: string = '';
  phone: string = '';
  full_name: string = ''
  password: string = '';

}
