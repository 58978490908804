import { AccountService } from '../../services/api/account.service';
import { ShipmentService } from '../../services/api/shipment.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { JqueryUtils } from '../../utils/jquery.utils';
import { StateService } from '../../services/api/state.service';
import { DistrictService } from '../../services/api/district.service';
import { PrintService } from '../../services/api/print.service';

@Component({
  selector: 'app-api-shipments',
  templateUrl: './api-shipments.component.html',
  styleUrls: ['./api-shipments.component.scss']
})

export class APIShipmentsComponent implements OnInit, OnDestroy {

  sess_acc: any;
  offset = 0;
  count = 20;
  show_client_selection = true;
  allow_assign = false;
  totalItems = 0;
  all_selected: any = false;
  any_selected: any = false;
  suds: any = [];
  shipments: any = [];
  barcodes: any = [];
  selection: any;
  tableParams: any = {
    assign_pfe: false
  };
  assignFEParams: any = {
    nps: [],
    selNpId: '',
    fes: [],
    selExecId: ''
  };
  editShipmentParams: any = { };
  fcmNotificationParams: any = { header: '', msg: '' };

  utypes = [
    { full_name: "All", uid: 0 },
    { full_name: "CFL", tag: 'intracity', uid: 1 },
    { full_name: "South/Upcountry", tag: 'intercity', uid: 2 },
    // { full_name: "Intercity", uid: 3 }
  ];
  selUploadTypeId = this.utypes[0].uid;
  upload_selection: any = '';

  assign_states = [
    { full_name: "All", tag: '', _id: 0 },
    { full_name: "Assigned", tag: 'assigned', _id: 1 },
    { full_name: "Unassigned", tag: 'unassigned', _id: 2 },
  ];
  selAssignStateId = this.assign_states[0]._id;

  clients = [{ user: { full_name: "All", user_id: "" , tag: "all" } }];
  selClientId = this.clients[0].user.user_id;

  states = [{ full_name: "All", _id: 0 , tag:"all" }];
  selPickupStateId = this.states[0]._id;

  pickup_districts = [ { full_name: "All", _id: 0 , tag:"all" }];
  selPickupDistrictId = this.pickup_districts[0]._id;
  subscriber_id: any = 'api-create-shipment';

  constructor(private accountService: AccountService, private dataService: DataService,
    private shipmentService: ShipmentService, private stateService: StateService, private ns: NotificationService,
    private printService: PrintService, private utilityService: UtilityService, private districtService: DistrictService,
    private router: Router, private jq: JqueryUtils) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.selection = {
      date: new Date().toISOString().substring(0, 10),
    };
    this.calculate_params();
    this.getStates(() => {
      this.applyDefaults(() => {
        this.getUploadHistory();
      });
    });
    this.getClients();
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  calculate_params() {
    this.show_client_selection = (this.sess_acc.user_type == 'admin');
    this.allow_assign = (this.sess_acc.user_type == 'admin');
  }

  applyDefaults(cb: any) {
    let state: any;
    if (this.sess_acc.user_type == 'admin') {
      state = this.getDefaultState();
    } else {
      state = this.getStateByTag(this.sess_acc.details.state);
    }
    this.selPickupStateId = state._id;
    this.getDistricts('pickup', true, cb);
  }

  refreshList() {
    this.getUploadHistory();
  }

  openSelectionAssignFEModal(selection: any) {
    let district: any = this.getDistrictById(this.selPickupDistrictId, 'pickup');
    if (selection == 'all') {
      if (district) {
        this.openAssignPFEModal(district.tag, { selection });
      } else {
        this.getSelectionCity((cities: any) => {
          if (cities.length != 1) {
            this.ns.showAlert(CONSTANTS.ERROR, 'List associated with more than one city. Please select one city for assigning Pickup Exceutive.');
          } else {
            this.openAssignPFEModal(cities[0], { selection });
          }
        });
      }
    } else {
      let suds = this.getSelectedSUDs();
      if (district) {
        for (let sud of suds) {
          if (sud.pickup_city.toLowerCase() != district.tag.toLowerCase()) {
            this.ns.showAlert(CONSTANTS.ERROR, 'Select Uploads contains more than one city for assigning Pickup Exceutive.');
            return;
          }
        }
        this.openAssignPFEModal(district.tag, { selection, selected_suds: suds });
      } else {
        let unique_cities: any = [];
        for (let sud of suds) {
          if (unique_cities.indexOf(sud.pickup_city) == -1) {
            unique_cities.push(sud.pickup_city);
          }
        }
        if(unique_cities.length != 1) {
          this.ns.showAlert(CONSTANTS.ERROR, 'Please select one city for assigning Pickup Exceutive.');
          return;
        }
        this.openAssignPFEModal(unique_cities[0], { selection, selected_suds: suds });
      }
    }
  }

  viewShipments(sud: any) {
    let root: any = (this.sess_acc.user_type == 'admin') ? `/${CONSTANTS.ROUTES.ROOT.ADMIN}` : `/${CONSTANTS.ROUTES.ROOT.USER}`;
    this.router.navigate([root, CONSTANTS.ROUTES.UPLOAD_SHIPMENTS], { queryParams: {upload_id: sud._id}});
  }

  openAssignFEModal(sud: any) {
    this.openAssignPFEModal(sud.pickup_city, { sud: sud, selection: 'row' });
  }

  openEditShipmentModal(sud: any) {
    this.editShipmentParams = {
      sud: sud,
      states: this.states,
      selStateId: this.states[1]._id
    }
    this.onSelState('edit_shipment')
    this.jq.openModal('editShipmentModal');
  }

  openAssignPFEModal(district_name: any, params: any) {
    this.getNPs(district_name, (nps: any)=> {
      if (!nps || (nps.length == 0)) {
        this.ns.showAlert(CONSTANTS.ERROR, 'No Partners Found for the pickup city.');
        return;
      }
      let np_id: any = nps[0].user.user_id;
      this.assignFEParams = {
        nps: nps,
        selNpId: np_id,
        selection: params.selection
      }
      this.getFEs(np_id, (response: any) => {
        this.assignFEParams.fes = response.accounts;
        if (this.assignFEParams.fes != null && this.assignFEParams.fes.length > 0) {
          this.assignFEParams.selExecId = this.assignFEParams.fes[0].account_id;
        }
        if (params.sud) {
          this.assignFEParams.upload_ids = [params.sud._id];
        } else if (params.selection == 'selected') {
          this.assignFEParams.upload_ids = [];
          for (let sud of params.selected_suds) {
            this.assignFEParams.upload_ids.push(sud._id);
          }
        }
        this.jq.openModal('assignFEModal');
      });
    });
  }

  closeAssignFEModal() {
    this.assignFEParams = { };
    this.jq.closeModal('assignFEModal');
  }

  closeEditShipmentModal() {
    this.editShipmentParams = { };
    this.jq.closeModal('editShipmentModal');
  }

  closeBarcodeModal() {
    this.shipments = [];
    this.barcodes = [];
    this.jq.closeModal('barcodeModal');
  }

  showBarcodes(sud: any) {
    this.getBarcodes(sud, (barcodes: any, shipments: any) => {
      this.barcodes = barcodes;
      this.shipments = shipments;
      if (this.barcodes.length > 0) {
        this.jq.openModal('barcodeModal');
      } else {
        this.ns.showAlert(CONSTANTS.ERROR, 'No Barcodes Found.');
      }
    });
  }

  downloadXls(sud: any) {
    let ip: any = {
      sud_id: sud._id
    };
    this.shipmentService.downloadUploadedXls(ip).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = sud.xls_type == null ? `${sud.file_name}.xlsx` : sud.file_name;
          // start download
          a.click();
          // this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  assign_class(sud: any) {
    if (sud.details.fe) {
      return { 'text-success': 1 };
    }
    return { 'text-success': 0 };
  }

  onSelUploadType() {
    this.getUploadHistory();
  }

  onSelAssignState() {
    this.getUploadHistory();
  }

  onSelState(selection: any) {
    if (selection ==' pickup') {
      this.getDistricts(selection, false, () => {
        this.getUploadHistory();
      });
    } else {
      this.getDistrictsForEditShipment(this.editShipmentParams);
    }
  }

  onSelClient() {
    this.getUploadHistory();
  }

  onSelDistrict(selection: any) {
    this.getUploadHistory();
  }

  onSelExecutive(params: any) { }

  onSelNp(params: any) {
    this.getFEs(params.selNpId, (response: any) => {
      params.fes = response.accounts;
      if (params.fes.length > 0) {
        params.selExecId = params.fes[0].account_id;
      } else {
        params.selExecId = "";
      }
    });
  }

  onDateChanged() { 
    this.getUploadHistory();
  }

  onAllSUDsSelected(event: any) {
    this.all_selected = event.target.checked;
    for (let idx = 0; idx < this.suds.length; idx++) {
      this.suds[idx].selected = this.all_selected;
    }
    this.any_selected = this.all_selected;
  }

  onSUDSelected(sud: any, event: any) {
    sud.selected = event.target.checked;
    let all_selected: any = true, any_selected: any = false;
    for (let idx = 0; idx < this.suds.length; idx++) {
      all_selected = all_selected && this.suds[idx].selected;
      any_selected = any_selected || this.suds[idx].selected;
    }
    this.all_selected = all_selected;
    this.any_selected = any_selected;
  }

  getDistricts(selection: any, select_client_locality: any, cb: any = null) {
    let state: any;
    if (selection == 'pickup') {
      state = this.getStateById(this.selPickupStateId);
    }
    if (state == null) {
      // this.ns.showAlert(CONSTANTS.ERROR, `${(selection == 'pickup') ? 'Pickup' : 'Delivery'} State should be selected`);
      this.pickup_districts = [{ full_name: "All", _id: 0 , tag:"all"}];
      this.selPickupDistrictId = this.pickup_districts[0]._id;
      if (cb != null) {
        cb();
      }
      return;
    }
    if (selection == 'pickup') {
      this.pickup_districts = [];
    }
    let input = { state: state.full_name };
    this.districtService.getDistricts(input).subscribe((response: any) => {
      let p = [{ full_name: "All", _id: 0 , tag:"all"}];
      response.districts.forEach((element: any, index: any) => {
        let data = { full_name:element, _id:index+1, tag:element };
        p.push(data)
      });
      let client: any, location: any
      if (selection == 'pickup') {
        this.pickup_districts = p;
        if (this.pickup_districts.length > 1) {
          if (select_client_locality) {
            if (this.show_client_selection) {
              client = this.getClientById(this.selClientId);
              location = this.getDistrictByTag(client.details.locality, selection);
            } else {
              location = this.getDistrictByTag(this.sess_acc.details.locality, selection);
            }
            this.selPickupDistrictId = location._id;
          } else {
            this.selPickupDistrictId = this.pickup_districts[1]._id;
          }
        }
      }
      if (cb != null) {
        cb();
      }
    });
  }

  getDistrictsForEditShipment(params: any, cb: any = null) {
    let state: any = this.getStateById(params.selStateId);
    if (state == null) {
      params.districts = [{ full_name: "All", _id: 0 , tag:"all"}];
      params.selDistrictId = this.pickup_districts[0]._id;
      if (cb != null) {
        cb();
      }
      return;
    }
    params.districts = [];
    let input = { state: state.full_name };
    this.districtService.getDistricts(input).subscribe((response: any) => {
      let p = [{ full_name: "All", _id: 0 , tag:"all"}];
      response.districts.forEach((element: any, index: any) => {
        let data = { full_name:element, _id:index+1, tag:element };
        p.push(data)
      });
      params.districts = p;
      params.selDistrictId = params.districts[1]._id;
      if (cb != null) {
        cb();
      }
    });
  }

  getStates(cb: any) {
    this.states = [];
    this.stateService.getStates().subscribe((response: any) => {
      let states: any = [{ full_name: "All", _id: 0 , tag:"all" }];
      response.states.forEach((state: any, idx: any) => {
        states.push({ full_name: state, _id: idx+1, tag: state });
      });
      this.states = states;
      if (cb) {
        cb();
      }
    });
  }

  getBarcodes(sud: any, cb: any) {
    let input: any = {
      upload_id: sud._id
    };
    this.shipmentService.getBarcodes(input).subscribe((response: any) => {
      let shipments: any = response.shipments;
      let barcodes: any = [];
      let shipment: any;
      for (let idx = 0; idx < shipments.length; idx++) {
        shipment = shipments[idx];
        for (let iidx = 0; iidx < shipment.total_boxes; iidx++) {
          barcodes.push({barcode: shipment.bar_code, date: shipment.date || '', obj: shipment, box_no: `${iidx+1}/${shipment.total_boxes}` });
        }
      }
      if (cb) {
        cb(barcodes, shipments);
      }
    });
  }

  getUploadHistory() {
    let input: any = this.getSelectedInputs();
    input["offset"] = this.offset;
    input["limit"] = 20;
    input["creation_type"] = "api";
    this.shipmentService.uploadHistory(input).subscribe((response: any) => {
      this.suds = [];
      response.suds.forEach((sud: any) => {
        sud.selected = false;
        sud.show_assign = (sud.details.processed == sud.details.total);
        sud.show_assign = sud.show_assign && [CONSTANTS.USER_TYPES.ADMIN].indexOf(this.sess_acc.user_type) > -1
      });
      this.suds = response.suds;
      this.totalItems = response.count;
    });
  }

  getClients() {
    this.clients = [];
    let input = {"user_type": "client"};
    this.accountService.getAccounts(input).subscribe((response: any) => {
      let clients: any = [{ user: { full_name: "All", user_id: "" , tag: "all" } }];
      response.accounts.forEach((element: any, index: any) => {
        clients.push(element);
      });
      this.clients = clients;
      this.selClientId = this.clients[0].user.user_id;
    });
  }

  getNPs(locality: any, cb: any) {
    let input = { "user_type": "np", locality: locality };
    this.accountService.getAccounts(input).subscribe((response: any) => {
      let nps: any = response.accounts;
      if (cb) {
        cb(nps);
      }
    });
  }

  getFEs(npId: any, cb: any) {
    let np: any = this.getNPById(npId, this.assignFEParams.nps);
    let np_id: any = "";
    if (np) {
      np_id = np.account_id;
    }
    let input = { "user_type": "np_fe", np_id: np_id, executive_type: 'pickup_executive' };
    this.accountService.getAccounts(input).subscribe((response: any) => {
      if (cb) {
        cb(response);
      }
    });
  }

  getSelectedInputs() {
    let input: any = { date: this.selection.date };
    let utype: any = this.getUploadTypeByUid(this.selUploadTypeId);
    if (utype) {
      input['upload_type'] = utype.tag;
    }
    let district: any = this.getDistrictById(this.selPickupDistrictId, 'pickup');
    if (district) {
      input['pickup_city'] = district.tag;
    }
    let assign_state: any = this.getAssignStateById(this.selAssignStateId);
    if (assign_state) {
      input['assign_status'] = assign_state.tag;
    }
    let client: any = this.getClientById(this.selClientId);
    if (client) {
      input['client_id'] = client.user.user_id;
    }
    input["creation_type"] = "api";
    return input;
  }

  getSelectionCity(cb: any) {
    let input: any = this.getSelectedInputs();
    this.shipmentService.getSelectedCity(input).subscribe((response: any) => {
      if (cb) {
        cb(response.pickup_cities);
      }
    });
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * 20;
    this.getUploadHistory();
  }

  assignExecutive(params: any) {
    let fe: any = this.getFEById(params.selExecId, params.fes);
    let assign_params: any = {};
    if (params.selection == 'all') {
      assign_params = { filter_params: this.getSelectedInputs() };
    } else {
      assign_params = {
        upload_ids: params.upload_ids
      };
    }
    let ip: any = {
      assign_params: assign_params,
      fe_id: fe.user.user_id
    };
    this.shipmentService.assignShipment(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "FE Assigned successfully");
      this.getUploadHistory();
      this.jq.closeModal('assignFEModal');
    });
  }

  editShipment(params: any) {
    let sud: any = params.sud;
    let state: any = this.getStateById(params.selStateId);
    let district: any = null;
    for (let idx = 0; idx < params.districts.length; idx++) {
      if ((params.districts[idx]._id != 0) && (params.districts[idx]._id == params.selDistrictId)) {
        district = params.districts[idx];
        break;
      }
    }
    let ip: any = {
      upload_ids: [sud._id],
      delivery_state: state.tag,
      delivery_city: district.tag
    };
    this.shipmentService.editShipment(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Shipment Updated successfully");
      this.processSud(sud);
      this.getUploadHistory();
      this.jq.closeModal('editShipmentModal');
    });
  }

  processSud(sud: any) {
    let ip: any = { sud_id: sud._id };
    this.shipmentService.processUpload(ip).subscribe((response: any) => {
    });
  }

  printBarcodes(sud: any) {
    this.getBarcodes(sud, (barcodes: any, shipments: any) => {
      let ip: any = { codes: barcodes };
      this.printService.printBarcodes(ip).subscribe((response: any) => {

      });
    });
  }

  updateShipments(sud: any) {
    this.openEditShipmentModal(sud);
  }

  clearSearch(selection: any, clear_on: any) {
    if (selection == 'pickup') {
      if (clear_on == 'state') {
        this.selPickupStateId = this.states[0]._id;
      }
      this.selPickupDistrictId = this.pickup_districts[0]._id;
    }
  }

  getSelectedSUDs() {
    let selected_suds: any = [];
    for (let idx = 0; idx < this.suds.length; idx++) {
      if (this.suds[idx].selected) {
        selected_suds.push(this.suds[idx]);
      }
    }
    return selected_suds;
  }

  getUploadTypeByUid(uid: any) {
    let utype: any = null;
    for (let idx = 0; idx < this.utypes.length; idx++) {
      if ((this.utypes[idx].uid != 0) && (this.utypes[idx].uid == uid)) {
        utype = this.utypes[idx];
        break;
      }
    }
    return utype;
  }

  getStateById(state_id: any) {
    let state: any = null;
    for (let idx = 0; idx < this.states.length; idx++) {
      if ((this.states[idx]._id != 0) && (this.states[idx]._id == state_id)) {
        state = this.states[idx];
        break;
      }
    }
    return state;
  }

  getDefaultState() {
    let state: any = null;
    for (let idx = 0; idx < this.states.length; idx++) {
      if (this.states[idx]._id == 0) {
        state = this.states[idx];
        break;
      }
    }
    return state;
  }

  getStateByTag(tag: any) {
    let state: any = null;
    for (let idx = 0; idx < this.states.length; idx++) {
      if ((this.states[idx]._id != 0) && (this.states[idx].tag.toLowerCase() == tag.toLowerCase())) {
        state = this.states[idx];
        break;
      }
    }
    return state;
  }

  getDistrictByTag(tag: any, selection: any) {
    let districts: any = [];
    if (selection == 'pickup') {
      districts = this.pickup_districts;
    }
    let district: any = null;
    for (let idx = 0; idx < districts.length; idx++) {
      if ((districts[idx]._id != 0) && (districts[idx].tag.toLowerCase() == tag.toLowerCase())) {
        district = districts[idx];
        break;
      }
    }
    return district;
  }

  getDistrictById(district_id: any, selection: any) {
    let districts: any = [];
    if (selection == 'pickup') {
      districts = this.pickup_districts;
    }
    let district: any = null;
    for (let idx = 0; idx < districts.length; idx++) {
      if ((districts[idx]._id != 0) && (districts[idx]._id == district_id)) {
        district = districts[idx];
        break;
      }
    }
    return district;
  }

  getAssignStateById(assign_state_id: any) {
    let assign_state: any = null;
    for (let idx = 0; idx < this.assign_states.length; idx++) {
      if ((this.assign_states[idx]._id != 0) && (this.assign_states[idx]._id == assign_state_id)) {
        assign_state = this.assign_states[idx];
        break;
      }
    }
    return assign_state;
  }

  getClientById(client_id: any) {
    let client: any = null;
    for (let idx = 0; idx < this.clients.length; idx++) {
      if ((this.clients[idx].user.user_id != "") && (this.clients[idx].user.user_id == client_id)) {
        client = this.clients[idx];
        break;
      }
    }
    return client;
  }

  getNPById(np_id: any, nps: any = []) {
    let np: any = null;
    for (let idx = 0; idx < nps.length; idx++) {
      if ((nps[idx].account_id != "") && (nps[idx].user.user_id == np_id)) {
        np = nps[idx];
        break;
      }
    }
    return np;
  }

  getFEById(fe_id: any, fes: any = []) {
    let fe: any = null;
    for (let idx = 0; idx < fes.length; idx++) {
      if ((fes[idx].account_id != "") && (fes[idx].account_id == fe_id)) {
        fe = fes[idx];
        break;
      }
    }
    return fe;
  }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
    this.dataService.offAPIError(this.subscriber_id);
  }
}
