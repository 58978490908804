<div class="row sub-header-buffer">
    <div class="col-md-12">  
        <table class="table ecom-table">
            <thead class="table-header">
                <tr>
                  <th class="text-center align-middle p-0" *ngIf="tableParams.show_selection">
                    <input type="checkbox" [checked]="all_selected" (change)="onAllShipmentsSelected($event)"
                      aria-label="Checkbox for selecting all shipments">
                  </th>
                  <th>
                      <div>Booking Date</div>
                      <div *ngIf="tableParams.show_delivered">Delivered Date</div>
                  </th>
                  <th>
                    <div>AWB Number</div>
                    <div>Store</div>
                  </th>
                  <th *ngIf="tableParams.show_picked">
                    <div>Picked By</div>
                    <div>Contact</div>
                  </th>
                  <th *ngIf="!tableParams.show_delivered">
                    <div>Delivering By</div>
                    <div>Contact</div>
                  </th>
                  <th *ngIf="tableParams.show_delivered">
                    <div>Delivered By</div>
                    <div>Contact</div>
                  </th>
                  <th *ngIf="tableParams.show_picked">
                    <div>Total Box</div>
                    <div>Total Weight</div>
                  </th>
                  <th *ngIf="tableParams.show_delivered">
                    <div>Name</div>
                    <div>Phone</div>
                  </th>
                  <th *ngIf="tableParams.show_delivered">
                    <div>Sign</div>
                  </th>
                  <th *ngIf="tableParams.show_pod">
                    <div>Sign</div>
                    <div>POD</div>
                  </th>
                  <th class="text-center" *ngIf="tableParams.has_actions">
                    <div>Actions</div>
                  </th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let shipment of shipments">
                  <td class="text-center align-middle p-0" *ngIf="tableParams.show_selection">
                    <input type="checkbox" [checked]="shipment.selected" (change)="onShipmentSelected(shipment, $event)"
                      aria-label="Checkbox for selecting shipment">
                  </td>
                  <td>
                    <div>{{shipment.created_date}}</div>
                      <div *ngIf="tableParams.show_delivered">{{shipment.details.rp_delivered?.dtt}}</div>
                  </td>
                  <td>
                    <div>{{shipment.details.bar_code}}</div>
                    <div>{{shipment.details.store}}</div>
                  </td>
                  <td *ngIf="tableParams.show_picked">
                    <div>{{shipment.details.pickup_executive_details?.full_name}}</div>
                    <div>{{shipment.details.pickup_executive_details?.phone}}</div>
                  </td>
                  <td *ngIf="!tableParams.show_delivered">
                    <div>{{shipment.details.delivery_executive_details?.full_name}}</div>
                    <div>{{shipment.details.delivery_executive_details?.phone}}</div>
                  </td>
                  <td *ngIf="tableParams.show_delivered">
                    <div>{{shipment.details.delivery_executive_details?.full_name}}</div>
                    <div>{{shipment.details.delivery_executive_details?.phone}}</div>
                  </td>
                  <td *ngIf="tableParams.show_picked">
                    <div>{{shipment.details.total_box}}</div>
                    <div>{{shipment.details.total_weight}}</div>
                  </td>
                  <td *ngIf="tableParams.show_delivered">
                    <div>{{shipment.details.rp_delivered?.sign_datas?.name}}</div>
                    <div>{{shipment.details.rp_delivered?.sign_datas?.phone}}</div>
                  </td>
                  <td *ngIf="tableParams.show_delivered">
                    <div>
                      <a href="javascript:void(0)" *ngIf="has_sign(shipment)" (click)="downloadSign(shipment)">View Sign</a>
                      <div *ngIf="!has_sign(shipment)">NO Sign</div>
                    </div>
                  </td>
                  <td *ngIf="tableParams.show_pod">
                    <div>
                      <a href="javascript:void(0)" *ngIf="has_pod(shipment, 'sign')" (click)="downloadSign(shipment)">View Sign</a>
                      <div *ngIf="!has_pod(shipment, 'sign')">NO Sign</div>
                    </div>
                    <div>
                      <a href="javascript:void(0)" *ngIf="has_pod(shipment, 'pod')" (click)="downloadPOD(shipment)">View Photo</a>
                      <div *ngIf="!has_pod(shipment, 'pod')">NO Photo</div>
                    </div>
                  </td>
                  <td class="text-center" *ngIf="tableParams.has_actions">
                    <i class="fas fa-eye text-primary mx-1" (click)="showOTP(shipment)"
                      *ngIf="tableParams.show_otp"></i>
                    <i class="fas fa-user-plus" (click)="openAssignSLFEModal([shipment], false)" *ngIf="tableParams.assign_fe"></i>
                    <i class="fas fa-arrow-right-from-bracket pl-2" (click)="openConfirmDeliverModal(shipment)" *ngIf="tableParams.deliver"></i>
                    <i class="fas fa-barcode pl-2" (click)="showBarcodes(shipment)" *ngIf="tableParams.barcode"></i>
                    <a href="https://www.google.com/maps/search/?api=1&query={{get_location(shipment, 'pickup')}}" target="_blank" *ngIf="show_rp_picked(shipment)">
                      <i class="fas fa-map-marker-alt mx-1 text-primary"></i>
                    </a>
                    <a href="https://www.google.com/maps/search/?api=1&query={{get_location(shipment, 'delivery')}}" target="_blank" *ngIf="show_rp_delivered(shipment)">
                      <i class="fas fa-map-marker-alt mx-1 text-secondary"></i>
                    </a>
                  </td>
              </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="15" class="center-align">
                        <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
                            <div class="col-md-12 pagenation">
                              <app-pagination [totalItems]="totalItems"
                                (onPrevSelected)="onPageSelected($event)" (onNextSelected)="onPageSelected($event)"
                                (onFirstSelected)="onPageSelected($event)" (onLastSelected)="onPageSelected($event)"
                                (onPageSelected)="onPageSelected($event)">
                              </app-pagination>
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="rpAssignSLFEModal" tabindex="-1" role="dialog"
  aria-labelledby="rpAssignSLFELabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>Assign Executive</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeAssignSLFEModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label>Delivery Executives</label>
            <div class="form-group">
                <select class="form-select select-dd" [(ngModel)]="assignParams.selExecId" (change)="onSelExecutive(assignParams)">
                    <option *ngFor="let fe of assignParams.fes" [selected]="fe.selected" [value]="fe.user.user_id"> {{ fe.user.full_name }}</option>
                </select>
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="assignExecutive(assignParams)">
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade mt-0" [attr.id]="rpSLBarcodeModalName" tabindex="-1" aria-labelledby="rpSLBarcodeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content rounded-0">
      <div class="modal-body">
        <div class="row print-margin" *ngFor="let barcode of viewBarcodeParams.barcodes">
          <div class="col-12">
            <div class="align-barcode d-flex w-bheader">
              <label for="barcode" class="top_text barcode-label">SAAP Logistics</label>
              <label for="barcode" class="top_text barcode-label ml-auto">{{ barcode.obj.details.store }}</label>
            </div>
            <ngx-barcode6 [bc-format]="'CODE128'" [bc-value]="barcode.barcode" [bc-width]="3" [bc-height]="100"
              [bc-text-align]="'center'" [bc-font-size]="10" [bc-display-value]="false"></ngx-barcode6>
            <div class="align-barcode d-flex flex-column">
              <label class="barcode-label">AWB : {{ barcode.barcode }}</label>
              <label class="barcode-label">Box : {{ barcode.box_no }}/{{ barcode.obj.details.total_box }}</label>
              <label class="barcode-label">Weight : {{ barcode.obj.details.total_weight }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBarcodeModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="printBarcodes(viewBarcodeParams)">Print</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="rpConfirmModal" tabindex="-1" role="dialog"
  aria-labelledby="rpConfirmModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>{{ confirmationParams.header }}</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close" style="cursor: pointer"
              (click)="closeConfirmModal(confirmationParams)">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
          <label>{{ confirmationParams.msg }}</label>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-right">
            <button class="btn btn-ecom" (click)="closeConfirmModal(confirmationParams)">
              Cancel
            </button>
            <button class="btn btn-ecom ml-2" (click)="confirm(confirmationParams)">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
