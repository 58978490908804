import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class PrintService extends ApiService {

  public printBarcodes(data: any) {
    return this.post(CONSTANTS.API.PRINTER.PRINT_BARCODES, data, "http://localhost:8001/saap_printer_api/");
  }
}
