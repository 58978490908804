<div class="container px-0">
  <div class="row sub-header-buffer">
    <div class="col-md-3">
      <input type="date" name="manifest_date" class="form-control" [(ngModel)]="selDate" (change)="onDateChanged()">
    </div>
    <div class="col-md-3" *ngIf="allow.city_selection">
      <select class="form-select select-dd h-100" [(ngModel)]="selCity" (change)="onSelectedCity()">
        <option *ngFor="let city of cities" [value]="city._id"> {{ city.name }}</option>
      </select>
    </div>
    <div class="col-md-3" *ngIf="allow.np_selection">
      <select class="form-select select-dd h-100" [(ngModel)]="selNP" (change)="onSelectedManifestNP()">
        <option *ngFor="let np of manifest_nps" [value]="np.account_id"> {{ np.user.full_name }}</option>
      </select>
    </div>
    <div class="col-md-3">
      <button type="button" class="btn btn-ecom" (click)="refreshManifests()">Refresh</button>
      <button type="button" class="btn btn-ecom mx-2" [disabled]="!any_selected" (click)="downloadSelectedManifest(calculateSelectedManifests())">Download</button>
    </div>
  </div>
  <div class="row sub-header-buffer">
    <table class="table ecom-table">
      <thead class="table-header">
        <tr>
          <th class="text-center align-middle p-0">
            <input type="checkbox" [checked]="all_selected" (change)="onAllManifestSelected($event)"
              aria-label="Checkbox for selecting all manifests">
          </th>
          <th>
            <div>DATE</div>
          </th>
          <th>
            <div>PICKUP CITY</div>
          </th>
          <th>
            <div>DELIVERY CITY</div>
          </th>
          <th>
            <div>FROM</div>
          </th>
          <th>
            <div>TO</div>
          </th>
          <th>
            <div>STATE</div>
          </th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let manifest of manifests">
          <td class="text-center align-middle p-0">
            <input type="checkbox" [checked]="manifest.selected" (change)="onManifestSelected(manifest, $event)"
              aria-label="Checkbox for selecting manifest">
          </td>
          <td>
            <div>{{manifest.date}}</div>
          </td>
          <td>
            <div>{{manifest.pickup_city.toUpperCase()}}</div>
          </td>
          <td>
            <div>{{manifest.delivery_city.toUpperCase()}}</div>
          </td>
          <td>
            <div>{{manifest.owner_np?.user?.full_name}}</div>
          </td>
          <td>
            <div>{{manifest.np?.user?.full_name}}</div>
          </td>
          <td>
            <div>{{manifest.state}}</div>
          </td>
          <td>
            <div class="row">
              <div class="col">
                <i class="fas fa-check text-success mx-1" (click)="openManifestGeneratedModal()" *ngIf="manifest.state == 'created'">
                </i>
                <i class="fas fa-user-plus" (click)="openAssignNPModal(manifest)" *ngIf="manifest.state == 'created' && false"></i>
                <i class="fas fa-file-excel text-primary mx-1" (click)="closeManifest(manifest)"
                  *ngIf="can_close(manifest)">
                </i>
                <i class="fas fa-exclamation text-danger mx-1" *ngIf="manifest.details.status != 'completed'">
                </i>
                <i class="fas fa-eye text-primary mx-1" (click)="viewShipments(manifest)">
                </i>
                <i class="fas fa-download text-primary mx-1" (click)="downloadManifest(manifest)">
                </i>
                </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="manifestGeneratedModal" tabindex="-1" role="dialog"
  aria-labelledby="manifestGeneratedModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>Info</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeManifestGeneratedModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
            <label>The Manifest is generated already. Please click on View or Download to check the shipments.</label>
        </div>
        <div class="row sub-header-buffer">
          <button class="btn btn-block btn-ecom ml-2" (click)="closeManifestGeneratedModal()">
              OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="assignNPModal" tabindex="-1" role="dialog"
  aria-labelledby="assignNPModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>Assign/Reassign</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeAssignNPModal()">&times;</span>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label>Network Partners</label>
            <div class="form-group">
                <select class="form-select select-dd" [(ngModel)]="assignNPParams.np_id" (change)="onSelNP()">
                    <option *ngFor="let np of nps" [value]="np._id"> {{ np.full_name }}</option>
                </select>
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="assignNP(assignNPParams)">
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
