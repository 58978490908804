import { Injectable } from "@angular/core";
import { CONSTANTS } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class DateUtil {

    constructor() { }

    mmddyyyyday(today: any) {
        var week = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
        var month = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');
        var day = week[today.getDay()];
        var dd = today.getDate();
        var mm = month[today.getMonth()];
        var yyyy = today.getFullYear();

        if (dd < 10) { dd = '0' + dd }

        return mm + " " + dd + ", " + yyyy + ", " + day
    }

    mmddyyyyhhmmss(today: any) {
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        var hh = today.getHours();
        var min = today.getMinutes();

        if (dd < 10) { dd = '0' + dd }

        return `${dd}-${mm}-${yyyy} ${hh}:${min}`
    }

    getDate(date: any, format: any) {
        if (date == null || date == undefined) {
            return;
        }
        let d = date;
        if (!(date instanceof Date)) {
            d = new Date(date);
        }
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        if (format == CONSTANTS.DATE_FORMAT.DDMMYYYY) {
            return [day, month, year].join('-');
        } else if (format == CONSTANTS.DATE_FORMAT.YYYYMMDD) {
            return [year, month, day].join('-');
        }
        return null;
    }

    getDateTime(date: any) {
        var d = new Date(date);
        var month = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');
        var dd = d.getDate();
        var mm = month[d.getMonth()];
        var year = d.getFullYear();
        var hh = d.getHours();
        var min = d.getMinutes();
        var ampm = hh >= 12 ? 'pm' : 'am';

        return dd + " " + mm + ", " + year + ", " + hh + ":" + min + " " + ampm;
    }

    getTime(date: any) {
        var d = new Date(date);
        var hh = d.getHours() + '';
        if (hh.length < 2) hh = '0' + hh;
        var min = d.getMinutes() + '';
        if (min.length < 2) min = '0' + min;
        var ampm = Number(hh) >= 12 ? 'PM' : 'AM';

        return hh + ":" + min + " " + ampm;
    }
}