import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ClickpostWrapperService extends ApiService {

  public createAccessToken() {
    return this.get(CONSTANTS.API.CLICKPOST.CREATE_TOKEN);
  }
  public regenerate(data: any) {
    return this.post(CONSTANTS.API.CLICKPOST.REGENERATE_KEYS, data);
  }
  public checkTokenValidity() {
    return this.get(CONSTANTS.API.CLICKPOST.CHECK_TOKEN_VALIDITY);
  }
  public getApiDetails() {
    return this.get(CONSTANTS.API.CLICKPOST.GET_API_DETAILS);
  }
  public getToken() {
    return this.get(CONSTANTS.API.CLICKPOST.GET_TOKEN);
  }
  public updateTokenTimeout(data: any) {
    return this.put(CONSTANTS.API.CLICKPOST.UPDATE_TOKEN_TIMEOUT, data);
  }
}
