<div class="container">
    <div class="row sub-header-buffer">
        <div class="col-md-2">
            <div class="form-group">
                <label>From</label>
                <input type="date" name="report_from_date" class="form-control" [(ngModel)]="selection.from" (change)="onDateChanged('from')">
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>To</label>
                <input type="date" name="report_to_date" class="form-control" [(ngModel)]="selection.to" (change)="onDateChanged('to')">
            </div>
        </div>
        <div class="col-md-4">
            <label>&nbsp;</label>
            <div class="form-group">
                <button class="btn btn-ecom mx-1" (click)="getReports()">VIEW</button>
                <button class="btn btn-ecom mx-1" (click)="downloadReport()">DOWNLOAD</button>
            </div>
        </div>
    </div>
    <div class="row sub-header-buffer">
        <div class="col-md-12">
            <table class="table ecom-table">
                <thead class="table-header">
                    <tr>
                        <th>
                            <div>Booking Time</div>
                            <div>Updated Time</div>
                        </th>
                        <th>AWB No</th>
                        <th>
                            <div>Client</div>
                            <div>Store</div>
                        </th>
                        <th>
                            <div>Pickup City</div>
                            <div>Delivery City</div>
                        </th>
                        <th>
                            <div>NP Name</div>
                            <div>Mode</div>
                        </th>
                        <th>
                            <div>Box</div>
                            <div>Weight</div>
                        <th>
                            <div>Shipment Status</div>
                            <div>Reason</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let report of reports">
                        <td>
                            <div>{{report.booking_date}}</div>
                            <div>{{report.delivered_dtt}}</div>
                        </td>
                        <td>{{report.barcode}}</td>
                        <td>
                            <div>{{report.client_details.user.full_name}}</div>
                            <div>{{report.consignee}}</div>
                        </td>
                        <td>
                            <div>{{report.pickup_city?.toUpperCase()}}</div>
                            <div>{{report.delivery_city?.toUpperCase()}}</div>
                        </td>
                        <td>
                            <div>{{report.pickup_np?.user?.full_name}}</div>
                            <div>{{report.mode}}</div>
                        </td>
                        <td>
                            <div>{{report.total_box}}</div>
                            <div>{{report.total_weight}}</div>
                        </td>
                        <td>
                            <div>{{getStatus(report.shipment_status)}}</div>
                            <div>{{report.rej_reason}}</div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="15" class="center-align">
                            <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
                                <div class="col-md-12 pagenation">
                                    <app-pagination [totalItems]="totalItems" [itemsPerPage]="count"
                                        (onPrevSelected)="onPageSelected($event)" (onNextSelected)="onPageSelected($event)"
                                        (onFirstSelected)="onPageSelected($event)" (onLastSelected)="onPageSelected($event)"
                                        (onPageSelected)="onPageSelected($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
