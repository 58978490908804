import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UtilityService } from '../utility.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  token: any;
  constructor(public httpClient: HttpClient, public utilityService: UtilityService) { };
  post(apiUrl: string, requestData: any, root_url: any = null) {
    if (root_url == null) {
      root_url = CONSTANTS.API.BASE_URL;
    }
    return this.httpClient.post(root_url + apiUrl, requestData);
  }
  put(apiUrl: string, requestData: any) {
    return this.httpClient.put(CONSTANTS.API.BASE_URL + apiUrl, requestData);
  }

  delete(apiUrl: string, queryParams: any) {
    let params = new HttpParams();
    let keys = Object.keys(queryParams);
    for (let i = 0; i < keys.length; i++) {
      params = params.append(keys[i], queryParams[keys[i]]);
    }
    return this.httpClient.delete(CONSTANTS.API.BASE_URL + apiUrl, { params: queryParams });
  }
  deleteWithBody(apiUrl: string, data: any) {
    return this.httpClient.delete(CONSTANTS.API.BASE_URL + apiUrl, { body: data });
  }
  channelGet(apiUrl: string) {
    return this.httpClient.get(CONSTANTS.API.CHANNEL_BASE_URL + apiUrl);
  }
  get(apiUrl: string) {
    return this.httpClient.get(CONSTANTS.API.BASE_URL + apiUrl);
  }
  getConfig(apiUrl: string) {
    return this.httpClient.get(apiUrl);
  }
  channelGetQueryParams(apiUrl: string, queryParams: any) {
    let params = new HttpParams();
    let keys = Object.keys(queryParams);
    for (let i = 0; i < keys.length; i++) {
      params = params.append(keys[i], queryParams[keys[i]]);
    }
    return this.httpClient.get(CONSTANTS.API.CHANNEL_BASE_URL + apiUrl, { params: queryParams });
  }
  getQueryParams(apiUrl: string, queryParams: any) {
    let params = new HttpParams();
    let keys = Object.keys(queryParams);
    for (let i = 0; i < keys.length; i++) {
      params = params.append(keys[i], queryParams[keys[i]]);
    }
    return this.httpClient.get(CONSTANTS.API.BASE_URL + apiUrl, { params: queryParams });
  }
  download(apiUrl: string, queryParams: any) {
    let params = new HttpParams();
    let keys = Object.keys(queryParams);
    for (let i = 0; i < keys.length; i++) {
      params = params.append(keys[i], queryParams[keys[i]]);
    }
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    return this.httpClient.get(CONSTANTS.API.BASE_URL + apiUrl, {
      responseType: 'blob',
      params: params,
      headers: new HttpHeaders({
        'token': this.token,
      })
    });
  }
}
