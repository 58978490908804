import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ChannelService extends ApiService {

  public getOtp(data: any) {
    return this.channelGetQueryParams(CONSTANTS.API.CHANNEL.GET_OTP, data);
  }
}
