<div class="container px-0">
    <div class="row sub-header-buffer">
        <div class="col-6">
            <div class="row">
                <div class="col">
                    <div class="font-weight-bold">Store</div>
                    <hr class="hr-adjusted"/>
                    <div class="mt-1">{{ store.toUpperCase() }}</div>
                </div>
                <div class="col">
                  <button type="button" class="btn btn-primary" (click)="openAddBarcodeModal()">Add</button>
                </div>
          </div>
        </div>
    </div>
    <div class="row sub-header-buffer">
        <div class="col-md-12">
            <table class="table ecom-table">
                <thead class="table-header">
                    <tr>
                      <th>
                        <div>Created Time</div>
                      </th>
                      <th>
                            <div>Client</div>
                        </th>
                        <th>
                            <div>Start</div>
                        </th>
                        <th>
                            <div>End</div>
                        </th>
                        <th>
                          <div>Count</div>
                        </th>
                      <th *ngIf="tableParams.show_actions">
                            <div>Actions</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let store_barcode of store_barcodes">
                      <td>
                        <div>{{store_barcode.created_date}}</div>
                      </td>
                      <td>
                            <div>{{store_barcode.client?.user?.full_name}}</div>
                        </td>
                        <td>
                            <div>{{store_barcode.pre_key}}{{store_barcode.start}}</div>
                        </td>
                        <td>
                            <div>{{store_barcode.pre_key}}{{store_barcode.end}}</div>
                        </td>
                        <td>
                          <div>{{store_barcode.count}}</div>
                        </td>
                        <td *ngIf="tableParams.show_actions">
                            <i class="fas fa-barcode pl-2" (click)="showBarcodes(store_barcode)"></i>
                        </td>
                    </tr>
                </tbody>
                <!-- <tfoot>
                    <tr>
                        <td colspan="15" class="center-align">
                            <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
                                <div class="col-md-12 pagenation">
                                    <app-pagination [totalItems]="totalItems"
                                        (onPrevSelected)="onPageSelected($event)" (onNextSelected)="onPageSelected($event)"
                                        (onFirstSelected)="onPageSelected($event)" (onLastSelected)="onPageSelected($event)"
                                        (onPageSelected)="onPageSelected($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot> -->
            </table>
        </div>
    </div>
</div>

<div class="modal fade mt-0" id="storeBarcodeModal" tabindex="-1" aria-labelledby="storeBarcodeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered barcode-dialog">
    <div class="modal-content rounded-0">
      <div class="modal-body">
        <div class="row">
          <div class="col border border-dark col-margin" *ngFor="let barcode of viewBarcodeParams.barcodes">
            <div class="hstack align-barcode">
              <label for="barcode" class="top_text barcode-label">SAAP Logistics</label>
              <label for="barcode" class="top_text barcode-label ms-auto">{{ barcode.obj.store.toUpperCase() }}</label>
            </div>
            <ngx-barcode6 [bc-format]="'CODE128'" [bc-value]="barcode.barcode" [bc-width]="2" [bc-height]="75"
              [bc-text-align]="'center'" [bc-font-size]="14" [bc-display-value]="false"></ngx-barcode6>
            <div class="align-barcode d-flex flex-column">
              <label class="barcode-label">AWB : {{ barcode.barcode }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBarcodeModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="printBarcodes(viewBarcodeParams)">Print</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="saveBarcodeModal" tabindex="-1" role="dialog"
  aria-labelledby="saveBarcodeModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content py-3">
      <div class="modal-body px-5">
        <div class="row">
          <div class="col-md-10">
            <h5>{{ saveBarcodeModalParams.header }}</h5>
          </div>
          <div class="col-md-2">
            <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
              style="cursor: pointer" (click)="closeSaveBarcodeModal()">&times;</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" *ngIf="saveBarcodeModalParams.show_client">
            <label>Client</label>
            <div class="form-group">
              <select class="form-select select-dd" [(ngModel)]="saveBarcodeModalParams.selectedClientId" (change)="onSaveBarcodeClientSelected()">
                <option *ngFor="let client of clients" [value]="client.user.user_id"> {{ client.user.full_name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <label>Count</label>
            <div class="form-group">
              <input type="number" min="1" class="form-control" [(ngModel)]="saveBarcodeModalParams.count" />
            </div>
          </div>
        </div>
        <div class="row sub-header-buffer">
          <div class="col-md-12 text-center">
            <button class="btn btn-ecom px-5" (click)="saveBarcode(saveBarcodeModalParams)">
              {{ saveBarcodeModalParams.btn_txt }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-fcm-notification [params]="fcmNotificationParams"></app-fcm-notification>
