<div class="container px-0">
    <div class="row sub-header-buffer">
        <div class="col-6">
            <div class="row">
                <div class="col">
                    <div class="font-weight-bold">Filename</div>
                    <hr class="hr-adjusted" />
                    <div class="mt-1">{{ sud?.file_name }}</div>
                </div>
                <div class="col">
                    <div class="font-weight-bold">Uploaded By</div>
                    <hr class="hr-adjusted" />
                    <div class="mt-1">{{ sud?.uploaded_by?.user?.full_name }}</div>
                </div>
                <div class="col">
                    <div class="font-weight-bold">Uploaded For</div>
                    <hr class="hr-adjusted" />
                    <div class="mt-1">{{ sud?.uploaded_for?.user?.full_name }}</div>
                </div>
                <div class="col">
                    <div class="font-weight-bold">Processed</div>
                    <hr class="hr-adjusted" />
                    <div class="mt-1">
                        {{sud?.details?.processed}}/{{sud?.details?.total}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row sub-header-buffer">
        <div class="col-md-12">
            <table class="table ecom-table">
                <thead class="table-header">
                    <tr>
                        <th>
                            <div>Date</div>
                        </th>
                        <th>
                            <div>State</div>
                        </th>
                        <th>
                            <div>District</div>
                        </th>
                        <th>
                            <div>WayBill No</div>
                        </th>
                        <th *ngIf="tableParams.show_actions">
                            <div>Actions</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let shipment of shipments">
                        <td>
                            <div>{{shipment.created_date}}</div>
                        </td>
                        <td>
                            <div>{{shipment.details?.delivery_state}}</div>
                        </td>
                        <td>
                            <div>{{shipment.details?.delivery_city}}</div>
                        </td>
                        <td>
                            <div>{{shipment.details?.order_no}}</div>
                        </td>
                        <td *ngIf="tableParams.show_actions">
                            <i class="fas fa-plus pl-2" (click)="openAddDistrictModal(shipment)"></i>
                            <i class="fas fa-link pl-2" (click)="openAddTagModal(shipment)"></i>
                            <i class="fas fa-barcode pl-2" (click)="showBarcodes(shipment)"></i>
                            <i class="fas fa-play text-success pl-2" (click)="processSud()"></i>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="15" class="center-align">
                            <div class="row sub-header-buffer float-right" *ngIf="totalItems > 0">
                                <div class="col-md-12 pagenation">
                                    <app-pagination [totalItems]="totalItems" (onPrevSelected)="onPageSelected($event)"
                                        (onNextSelected)="onPageSelected($event)"
                                        (onFirstSelected)="onPageSelected($event)"
                                        (onLastSelected)="onPageSelected($event)"
                                        (onPageSelected)="onPageSelected($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<div class="modal fade mt-0" id="manifestUSLBarcodeModal" tabindex="-1" aria-labelledby="manifestSLBarcodeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content rounded-0">
            <div class="modal-body" id="print-section">
                <div class="row pt-3">
                    <div class="col-md-auto" *ngFor="let barcode of barcodes">
                        <ngx-barcode6 [bc-format]="'CODE128'" [bc-value]="barcode.barcode" [bc-width]="2"
                            [bc-height]="100" [bc-text-align]="'center'" [bc-font-size]="10"
                            [bc-display-value]="true"></ngx-barcode6>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeBarcodeModal()">Cancel</button>
                <button type="button" class="btn btn-primary" [useExistingCss]="true" printSectionId="print-section"
                    ngxPrint (click)="printBarcodes()">Print</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="addDistrictModal" tabindex="-1" role="dialog"
    aria-labelledby="addDistrictModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content py-3">
            <div class="modal-body px-5">
                <div class="row">
                    <div class="col-md-10">
                        <h5>Add District</h5>
                    </div>
                    <div class="col-md-2">
                        <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
                            style="cursor: pointer" (click)="closeAddDistrictModal()">&times;</span>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-md-12">
                        <label>State</label>
                        <div class="form-group">
                            <select class="form-select select-dd" [(ngModel)]="addDistrictParams.selectedState"
                                (change)="onStateSelected('add_district', addDistrictParams)">
                                <option *ngFor="let state of addDistrictParams.states" [value]="state._id"> {{
                                    state.full_name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-md-12">
                        <label>District</label>
                        <div class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="addDistrictParams.district"
                                />
                        </div>
                    </div>
                    <div class="row sub-header-buffer">
                        <div class="col-md-12 text-center">
                            <button class="btn btn-ecom px-5" (click)="addDistrict(addDistrictParams)">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="updateDistrictTagModal" tabindex="-1"
    role="dialog" aria-labelledby="updateDistrictTagModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content py-3">
            <div class="modal-body px-5">
                <div class="row">
                    <div class="col-md-10">
                        <h5>Update District</h5>
                    </div>
                    <div class="col-md-2">
                        <span aria-hidden="true" class="close" data-dismiss="modal" aria-label="Close"
                            style="cursor: pointer" (click)="closeUpdateDistrictTagModal()">&times;</span>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-md-12">
                        <label>State</label>
                        <div class="form-group">
                            <select class="form-select select-dd"
                                [(ngModel)]="updateDistrictTagParams.selectedState"
                                (change)="onStateSelected('update_tag', updateDistrictTagParams)">
                                <option *ngFor="let state of updateDistrictTagParams.states" [value]="state._id"> {{
                                    state.full_name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-md-12">
                        <label>District</label>
                        <div class="form-group">
                            <select class="form-select select-dd"
                                [(ngModel)]="updateDistrictTagParams.selectedDistrict"
                                (change)="onDistrictSelected()">
                                <option *ngFor="let district of updateDistrictTagParams.districts"
                                    [value]="district._id"> {{ district.full_name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-md-12">
                        <label>Tag</label>
                        <div class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="updateDistrictTagParams.tag"
                                [disabled]="true" />
                        </div>
                    </div>
                    <div class="row sub-header-buffer">
                        <div class="col-md-12 text-center">
                            <button class="btn btn-ecom px-5"
                                (click)="updateDistrictTag(updateDistrictTagParams)">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>