import { Component, OnDestroy, OnInit } from '@angular/core';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { Router } from '@angular/router';
import { ReportService } from '../../services/api/report.service';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {

  offset = 0;
  count = 20;
  totalItems = 0;
  reports: any = [];
  selection: any;
  token: any;
  fcmNotificationParams: any = { header: '', msg: '' };
  subscriber_id: any = 'report';

  constructor(private dataService: DataService, private reportService: ReportService,
    private ns: NotificationService, private utilityService: UtilityService, private jq: JqueryUtils,
    private router: Router) { }

  ngOnInit(): void {
    this.token = sessionStorage.getItem(CONSTANTS.COOKIE.HEADER);
    this.selection = {
      from: new Date().toISOString().substring(0, 10),
      to: new Date().toISOString().substring(0, 10)
    };
    this.loadReports();
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  onDateChanged(selected_date: any) {
    if (this.selection.from <= this.selection.to) {
      this.loadReports();
    }
  }

  loadReports() {
    this.offset = 0;
    this.totalItems = 0;
    this.getReports();
  }

  onPageSelected(currentPage: any) {
    this.offset = currentPage * this.count;
    this.getReports();
  }

  getReports() {
    if (this.selection.from > this.selection.to) {
      this.ns.showAlert(CONSTANTS.ERROR, 'From Date cannot be greater than To Date');
      return;
    }
    let input = {
      "offset": this.offset,
      "limit": this.count,
      "from_date": this.selection.from,
      "to_date": this.selection.to
    };
    this.reportService.getReports(input).subscribe((response: any) => {
      this.reports = [];
      this.reports = response.mis_report;
      this.totalItems = response.count;
    });
  }

  getStatus(status: any) {
    let names: any = CONSTANTS.SHIPMENT_DISPLAY_NAMES;
    return names.booking[status] || names[status];
  }

  downloadReport() {
    let input = {
      "from_date": this.selection.from,
      "to_date": this.selection.to
    };
    this.reportService.downloadReport(input).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'report.xlsx';
          a.click();
          this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }
}
