import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CONSTANTS } from './constants';
import { ChangePasswordComponent } from './components/b2b/b2b_changepassword/changepassword.component';
import { CreateShipmentComponent } from './components/createshipment/createshipment.component';
import { ListComponent as CSListomponent } from './components/createshipment/list/list.component';
import { APIShipmentsComponent } from './components/api-shipments/api-shipments.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoadDetailsComponent } from './components/loaddetails/loaddetails.component';
import { LoginComponent } from './components/login/login.component';
import { ManifestComponent } from './components/manifest/manifest.component';
import { ManifestShipmentListComponent } from './components/manifest-shipment-list/manifest-shipment-list.component';
import { RPManifestComponent } from './components/rp-manifest/rp-manifest.component';
import { PickupManifestComponent } from './components/pickup-manifest/pickup-manifest.component';
import { ReportComponent } from './components/report/report.component';
import { RPReportComponent } from './components/rp-report/rp-report.component';
import { ReversePickupViewComponent  } from './components/reverse-pickup-view/reverse-pickup-view.component';
import { StoreComponent } from './components/store/store.component';
import { UserComponent } from './components/user/user.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ForwardDeliveryViewComponent } from './components/forward-delivery-view/forward-delivery-view.component';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { RPManifestShipmentListComponent } from './components/rp-manifest-shipment-list/rp-manifest-shipment-list.component';
import { StoreBarcodeListComponent } from './components/store-barcode-list/store-barcode-list.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: CONSTANTS.ROUTES.INVOICE_DETAILS, component: LoadDetailsComponent },
  { path: CONSTANTS.ROUTES.ROOT.ADMIN, component: WrapperComponent,
    children: [
      { path: CONSTANTS.ROUTES.UPLOADS, component: CreateShipmentComponent },
      { path: CONSTANTS.ROUTES.UPLOAD_SHIPMENTS, component: CSListomponent },
      { path: CONSTANTS.ROUTES.API_SHIPMENTS, component: APIShipmentsComponent },
      { path: CONSTANTS.ROUTES.DASHBOARD, component: DashboardComponent },
      { path: CONSTANTS.ROUTES.USERS, component: UserComponent },
      { path: CONSTANTS.ROUTES.STORES, component: StoreComponent },
      { path: CONSTANTS.ROUTES.VIEW, component: ForwardDeliveryViewComponent },
      { path: CONSTANTS.ROUTES.FORWARD_REPORT, component: ReportComponent },
      { path: CONSTANTS.ROUTES.RP_REPORT, component: RPReportComponent },
      { path: CONSTANTS.ROUTES.RP_VIEW, component: ReversePickupViewComponent },
      { path: CONSTANTS.ROUTES.DELIVERY_MANIFEST, component: ManifestComponent },
      { path: CONSTANTS.ROUTES.PICKUP_MANIFEST, component: PickupManifestComponent },
      { path: CONSTANTS.ROUTES.MANIFEST_SHIPMENT, component: ManifestShipmentListComponent },
      { path: CONSTANTS.ROUTES.RP_MANIFEST, component: RPManifestComponent },
      { path: CONSTANTS.ROUTES.RP_MANIFEST_SHIPMENT, component: RPManifestShipmentListComponent },
      { path: CONSTANTS.ROUTES.STORE_BARCODES, component: StoreBarcodeListComponent },
    ]
  }, {
    path: CONSTANTS.ROUTES.ROOT.USER, component: WrapperComponent,
    children: [
      { path: CONSTANTS.ROUTES.UPLOADS, component: CreateShipmentComponent },
      { path: CONSTANTS.ROUTES.UPLOAD_SHIPMENTS, component: CSListomponent },
      { path: CONSTANTS.ROUTES.API_SHIPMENTS, component: APIShipmentsComponent },
      { path: CONSTANTS.ROUTES.VIEW, component: ForwardDeliveryViewComponent },
      { path: CONSTANTS.ROUTES.RESET, component: ChangePasswordComponent },
      { path: CONSTANTS.ROUTES.FORWARD_REPORT, component: ReportComponent },
      { path: CONSTANTS.ROUTES.RP_REPORT, component: RPReportComponent },
      { path: CONSTANTS.ROUTES.RP_VIEW, component: ReversePickupViewComponent },
      { path: CONSTANTS.ROUTES.DASHBOARD, component: DashboardComponent },
      { path: CONSTANTS.ROUTES.DELIVERY_MANIFEST, component: ManifestComponent },
      { path: CONSTANTS.ROUTES.PICKUP_MANIFEST, component: PickupManifestComponent },
      { path: CONSTANTS.ROUTES.MANIFEST_SHIPMENT, component: ManifestShipmentListComponent },
      { path: CONSTANTS.ROUTES.RP_MANIFEST, component: RPManifestComponent },
      { path: CONSTANTS.ROUTES.RP_MANIFEST_SHIPMENT, component: RPManifestShipmentListComponent },
      { path: CONSTANTS.ROUTES.USERS, component: UserComponent },
      { path: CONSTANTS.ROUTES.STORES, component: StoreComponent },
      { path: CONSTANTS.ROUTES.STORE_BARCODES, component: StoreBarcodeListComponent },
      { path: CONSTANTS.ROUTES.SETTINGS, component: SettingsComponent },
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
