import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipmentService } from '../../services/api/shipment.service';
import { AccountService } from '../../services/api/account.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';
import { CONSTANTS } from '../../constants';
import { NotificationService } from '../../services/notification.service';
import { JqueryUtils } from '../../utils/jquery.utils';

@Component({
  selector: 'app-pickup-manifest',
  templateUrl: './pickup-manifest.component.html',
  styleUrls: ['./pickup-manifest.component.scss']
})
export class PickupManifestComponent implements OnInit, OnDestroy {

  sess_acc: any;
  selDate: any;

  manifests: any = [];
  fcmNotificationParams: any = { header: '', msg: '' };
  subscriber_id: any = 'pickup-manifest';

  constructor(private accountService: AccountService, private dataService: DataService,
    private shipmentService: ShipmentService, private ns: NotificationService,
    private utilityService: UtilityService, private jq: JqueryUtils, private router: Router) { }

  ngOnInit(): void {
    this.sess_acc = JSON.parse(sessionStorage.getItem(CONSTANTS.COOKIE.ACCOUNT) || '{}');
    this.selDate = new Date().toISOString().substring(0, 10);
    this.onDateChanged();
    this.dataService.onFcmNotificationReceived(this.subscriber_id).subscribe(res => {
      this.fcmNotificationParams = {
        header: res.data.data_title,
        msg: res.data.data_body
      };
      this.jq.openModal('fcmNotificationModal');
    });
    this.dataService.onLoggedOut(this.subscriber_id).subscribe(res => {
      this.utilityService.removeAllCookies();
      this.router.navigate(['/']);
    });
  }

  onDateChanged() {
    this.loadManifests();
  }

  openManifestGeneratedModal() {
    this.jq.openModal('pickupManifestGeneratedModal');
  }

  closeManifest(manifest: any) {
    let ip: any = {
      shipment_manifest_id: [manifest._id]
    };
    this.shipmentService.generatePickupManifest(ip).subscribe((response: any) => {
      this.ns.showAlert(CONSTANTS.SUCCESS, "Manifest Generate successfully");
      this.loadManifests();
    });
  }

  closeManifestGeneratedModal() {
    this.jq.closeModal('pickupManifestGeneratedModal');
  }

  downloadManifest(manifest: any) {
    let ip: any = {
      manifest_id: manifest._id
    };
    this.shipmentService.downloadPickupManifest(ip).subscribe((response: any) => {
      response.text().then((r: any) => {
        let error;
        try {
          let json = JSON.parse(r);
          if (json.response_code) {
            this.ns.showAlert(CONSTANTS.ERROR, json.errors[0].data);
          } else {
            error = new Error();
          }
        } catch (e) {
          error = e;
        }
        if (error) {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(response);
          a.download = 'pickup_manifest.pdf';
          // start download
          a.click();
          // this.ns.showAlert(CONSTANTS.SUCCESS, "Downloaded successfully");
        }
      });
    });
  }

  loadManifests(retain_selection: any = false) {
    let ip: any = {
      date: this.selDate,
    };
    this.shipmentService.getPickupManifests(ip).subscribe((response: any) => {
      this.manifests = [];
      this.manifests = response.pickup_manifest.map((manifest: any) => {
        return manifest;
      });
    });
  }

  refreshManifests() {
    this.loadManifests();
  }

  ngOnDestroy(): void {
    this.dataService.offFcmNotificationReceived(this.subscriber_id);
    this.dataService.offLoggedOut(this.subscriber_id);
  }
}
